import styled from 'styled-components/macro'

import {
  AccordionRoot,
  AccordionItem,
  AccordionHeader,
  AccordionTrigger,
  AccordionContent,
} from '../components/Accordion'

const BREAKPOINT_HANDHELD = '479px'
const BREAKPOINT_LANDSCAPE = '767px'
const BREAKPOINT_TABLET = '991px'
const BREAKPOINT_WIDE = '1440px'

export const Container = styled.div`
  position: static;
  max-width: ${p => p.$full ? 'auto' : p.$wide ? '1600px' : p.narrow ? '600px' : '1200px'};
  padding: 2vw 3vw;
  margin: 0 auto;

  @media screen and (max-width: ${BREAKPOINT_LANDSCAPE}) {
    padding: 4vw 4vw;
  }
  @media screen and (max-width: ${BREAKPOINT_HANDHELD}) {
    padding: 6vw 4vw;
  }
`
export const Section = styled.div`
  // TODO?
`

// HEADER

export const HeaderWrapper = styled.div`
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-column-gap: 1rem;
  grid-template-columns: auto auto;
`
export const LogoWrapper = styled.div`
  text-decoration: none;
`
export const LogoText = styled.h1`
  margin: 0;
  font-family: var(--font-family-display);
  line-height: 1;
  color: var(--text-primary);
  font-size: 1.1rem;
  font-weight: 600;
  text-decoration: none;
`

// FOOTER

export const FooterWrapper = styled.div`
  display: grid;
  margin: 2rem 0 5rem;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-columns: 1fr 1fr 1fr max-content;

  @media screen and (max-width: ${BREAKPOINT_LANDSCAPE}) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: ${BREAKPOINT_HANDHELD}) {
    grid-template-columns: 1fr;
  }
`
export const FooterLink = styled.a`
  display: block;
  margin: 0 0 0.5rem;
  color: var(--text-secondary);
  text-decoration: none;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
  
  :focus {
    text-decoration: underline;
  }
`
export const FooterHeading = styled.div`
  margin: 0 0 0.5rem;
  font-size: 1rem;
  font-weight: 600;
`
export const BasementText = styled.div`
  color: var(--text-secondary);
  font-size: 0.85rem;
  /* text-align: center; */

  a {
    color: inherit;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
`

// HERO

export const HeroWrapper = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: 7vw 0;
  /* text-align: center; */

  @media screen and (min-width: ${BREAKPOINT_WIDE}) {
    max-width: 1200px;
  }
`
export const HeroBulletin = styled.div`
  display: inline-block;
  padding: .15rem .75rem;

  /* color: var(--destructive); */
  color: hsl(0 100% 60%);
  font-size: var(--s);
  font-weight: 500;
  border-radius: 100px;
  /* background: var(--oc-red-1); */
  background: hsl(20 100% 97%);
`
export const HeroHeading = styled.h1`
  margin: 20px 0 10px;
  font-family: var(--font-family-display);
  line-height: 1;
  color: var(--text-primary);
  font-size: 4.2rem;
  font-weight: 700;
  letter-spacing: -0.04rem;

  @media screen and (min-width: ${BREAKPOINT_WIDE}) {
    font-size: 5.4rem;
  }
  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    font-size: 3.2rem;
  }
  @media screen and (max-width: ${BREAKPOINT_LANDSCAPE}) {
    font-size: 3.2rem;
  }
  @media screen and (max-width: ${BREAKPOINT_HANDHELD}) {
    font-size: 2.6rem;
  }
`
export const HeroSubheading = styled.div`
  max-width: 780px;
  margin: 0 0 2rem;
  color: var(--text-secondary);
  font-size: 1.5rem;

  @media screen and (min-width: ${BREAKPOINT_WIDE}) {
    max-width: 1000px;
    font-size: 1.7rem;
  }
  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    font-size: 1.3rem;
  }
  @media screen and (max-width: ${BREAKPOINT_HANDHELD}) {
    font-size: 1.2rem;
  }
`
export const HeroParagraph = styled.div`
  max-width: 600px;
  margin: 2rem 0 2rem;
  color: var(--text-secondary);
  font-size: 1.2rem;
  
  span {
    color: var(--text-primary);
    font-weight: 500;
    /* color: orange; */
    /* background: yellow; */
    /* background: #fffcab; */
    /* padding: 4px; */
    /* margin: -4px; */
    /* border-bottom: 4px solid yellow; */
  }
`
export const HeroObjections = styled.div`
  margin: 0.5rem 0 0;
  color: var(--text-secondary);
  font-size: 0.85rem;
  letter-spacing: 0.02rem;
`

export const ColorBg = styled.div`
  background: url('/gradient.png');
  background-size: cover;
  width: 100vw;
  min-height: 60vh;
`

// FEATURES

export const FeaturesWrapper = styled.div`
  display: grid;
  grid-column-gap: 3rem;
  grid-template-columns: 10fr 15fr;
  justify-items: center;
  align-items: center;
  align-content: center;
  
  margin: 8rem 0;
  
  @media screen and (max-width: ${BREAKPOINT_LANDSCAPE}) {
    grid-row-gap: 2rem;
    grid-template-columns: 1fr;
  }
`
export const FeaturesBlock = styled.div`
  width: 100%;
  height: 100%;
  max-width: 520px;
`
export const FeaturesHeading = styled.h3`
  font-family: var(--font-family-display);
  margin: 20px 0 10px;
  font-size: 2rem;
  line-height: 1;
  font-weight: 700;
  letter-spacing: -0.03rem;
`
export const FeaturesText = styled.div`
  color: var(--text-secondary);
`
export const FeaturesImage = styled.img`
  width: 100%;
  margin: 0 auto;
  background-color: var(--background-item);
  min-height: 300px;
`

// FORMS

export const FormBlock = styled.form`
  margin: 0;
`
export const FormWrapper = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-column-gap: 0.5rem;
  grid-template-columns: max-content max-content;

  @media screen and (max-width: ${BREAKPOINT_HANDHELD}) {
    grid-template-columns: 1fr;
  }
`
export const FormTextInput = styled.input`
  min-width: 18rem;
  margin: 0;
  padding: 24px 0;
  border-radius: 3px;
  background-color: ${p => p.white ? 'white' : 'var(--background-item)'};
  box-shadow: ${p => p.white && '1px 1px 3px 0 rgba(0, 0, 0, 0.11)'};
  font-size: 1rem;

  &::placeholder {
    color: var(--text-secondary);
  }

  opacity: 0.9; 
  &:hover {
    background-color: var(--background-item);
    opacity: 1;
  }
  @media screen and (max-width: ${BREAKPOINT_HANDHELD}) {
    min-width: auto;
  }
`

// BIG HEADING

export const BigHeadingWrapper = styled.div`
  /* text-align: center; */
`
export const BigHeadingHeading = styled.h2`
  font-family: var(--font-family-display);
  line-height: 36px;
  margin: 20px 0 10px;
  color: var(--text-primary);
  font-size: 2.4rem;
  font-weight: 800;
  letter-spacing: -0.03rem;
`
export const BigHeadingSub = styled.div`
  max-width: 500px;
  margin: 0 0;
  color: var(--text-secondary);
  font-size: 1.4rem;
`
export const BigHeadingText = styled.div`
  max-width: 800px;
  margin: 1rem auto 0;
  color: var(--text-secondary);
`

// BANNER LINK

export const BannerLinkWrapper = styled.div`
  display: grid;
  margin: 0 auto;
  padding: 1.5rem;
  align-items: center;
  grid-column-gap: 1rem;
  background-color: var(--background-item);
  opacity: 0.9;
  transition: background-color 200ms ease;
  color: var(--text-secondary);
  text-decoration: none;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`
export const BannerLinkText = styled.div`
  color: var(--text-secondary);
  text-decoration: none;
`
export const BannerLinkArrow = styled.div`
  width: 24px;
  height: 24px;
`

// ARTICLE LIST

export const ArticleListWrapper = styled.div`
  display: grid;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-columns: 1fr 1fr 1fr;
  
  margin: 0 0 6rem;
  
  @media screen and (max-width: ${BREAKPOINT_HANDHELD}) {
    grid-template-columns: 1fr;
    /* max-width: 100%; */
    grid-column-gap: 1rem;
    grid-template-rows: auto auto;
  }
`
export const ArticleListItem = styled.a`
  text-decoration: none;
  color: var(--text-primary);
  display: block;
`
export const ArticleListThumb = styled.div`
  width: 100%;
  min-height: 240px;
  margin: 0 0 1rem;
  background: #eee;
`
export const ArticleListTitle = styled.h3`
  margin: 0 0 0.5em;
  font-family: var(--font-family-display);
  line-height: 1.2;
  font-weight: 700;
  font-size: 1.2rem;
`
export const ArticleListSub = styled.div`
  color: var(--text-secondary);
`

// FAQ

export const FaqWrapper = styled(AccordionRoot)`
  max-width: 800px;
  margin: 0 auto 2rem;
`
export const FaqItem = styled(AccordionItem)`
  display: block;
  overflow: hidden;
  width: 100%;
`
export const FaqTarget = styled(AccordionHeader)`
  width: 100%;
  margin: 0;
  padding: 0;
  border-top: 1px solid var(--hairline);
`
export const FaqQuestion = styled(AccordionTrigger)`
  display: block;
  width: 100%;

  margin: 0;
  padding: 1rem 0;

  background: transparent;
  color: var(--text-primary);
  border: none;

  text-align: left;
  font-size: 1rem;
  font-weight: 600;
  font-family: inherit;

  cursor: pointer;
  appearance: none;
`
export const FaqAnswerContainer = styled(AccordionContent)`
  position: static;
  display: block;
  background-color: transparent;
`
export const FaqAnswer = styled.div`
  padding: 0 4rem 1rem 1rem;
  color: var(--text-secondary);
`

// FEATURE WIDGETS

export const FeatureWidgetsWrapper = styled.div`
  display: grid;
  max-width: 830px;
  margin: 0 auto;
  padding: 0;
  grid-column-gap: 2rem;
  grid-template-columns: 1fr 1fr 1fr;

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: ${BREAKPOINT_HANDHELD}) {
    grid-template-columns: 1fr;
  }
`
export const FeatureWidgetsItem = styled.div`
  display: grid;
  align-items: center;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-columns: max-content auto;
`
export const FeatureWidgetsIconWrapper = styled.div`
  display: flex;
  width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr;
  border-radius: 4px;
  background-color: var(--background-item);
`
export const FeatureWidgetsIconImage = styled.img`
  width: 20px;
  height: 18px;
  filter: invert(100%);
`
export const FeatureWidgetsText = styled.div`
  font-size: 1rem;
  font-weight: 500;
`
export const FeatureWidgetsMore = styled.div`
  padding: 1rem 0;
  text-align: center;
`

// SMALL HEADING

export const SmallHeadingWrapper = styled.div`
  margin: 4rem 0 3rem;
  /* text-align: center; */
`
export const SmallHeadingHeading = styled.h2`
  margin: 0 0 0.5rem;
  font-family: var(--font-family-display);
  font-size: 2rem;
  line-height: 36px;
  font-weight: 800;
`
export const SmallHeadingSub = styled.div`
  color: var(--text-secondary);
  font-size: 1.1rem;
`

// FINAL CTA

export const FinalCtaWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-column-gap: 3rem;
  grid-template-columns: auto max-content;
  background: #f5f5f5;
  padding: 2rem;
  margin: 0 0 8rem;
  
  @media screen and (max-width: ${BREAKPOINT_LANDSCAPE}) {
    grid-template-columns: auto;
    grid-row-gap: 3rem;
  }
`
export const FinalCtaHeading = styled.h2`
  margin: 0 0 .5rem;
  font-family: var(--font-family-display);
  font-size: 1.6rem;
  font-weight: 700;
`
export const FinalCtaSub = styled.div`
  color: var(--text-secondary);
`

// EYEBROW

export const Eyebrow = styled.div`
  margin: 0 0 0.25rem;
  font-family: var(--font-family-display);
  color: var(--text-secondary);
  font-size: 0.8rem;
  font-weight: 500;
  letter-spacing: 0.07rem;
  text-transform: uppercase;
`

// SMALL HERO

export const SmallHeroWrapper = styled.div`
  padding: 5rem 0;
  /* text-align: center; */
`
export const SmallHeroHeading = styled.h2`
  margin: 0 0 1rem;
  font-family: var(--font-family-display);
  font-size: 2rem;
  line-height: 36px;
  font-weight: 900;
`
export const SmallHeroSub = styled.div`
  max-width: 500px;
  margin: 0 0 2rem;
  color: var(--text-secondary);
  font-size: 1.1rem;
  /* text-align: center; */
`
export const SmallHeroObjections = styled.div`
  padding: 1rem 0;
  color: var(--text-secondary);
  font-size: 0.85rem;
`

// SOCIAL ICONS

export const SocialIconsWrapper = styled.div`
  display: flex;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
`
export const SocialIconsLink = styled.a`
  margin: 0 0 0 1rem;
  color: var(--text-secondary);
  text-decoration: none;
  display: block;
  opacity: .3;

  &:hover {
    opacity: .4;
  }
`
export const SocialIconsImage = styled.img`
  display: block;
  width: 24px;
  height: 24px;
`

// WARNING BANNER

export const WarningBanner = styled.div`
  display: inline-block;
  margin: 0 0 1rem;
  padding: 4px 18px;
  border-radius: 1000px;
  background-color: rgba(254, 40, 39, 0.16);
  font-family: var(--font-family-display);
  color: var(--text-secondary);
  font-size: 0.9rem;
  font-weight: 500;
`
