import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { useState } from 'react'

import groupByArray from '../../helpers/groupBy'
import {
  PopoverRoot,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
} from '../../components/Popover'
import Questions from '../../data/questions'

const Errors = ({
  manualErrors,
}) => {
  const [ isShowingErrors, setIsShowingErrors ] = useState(false)

  const errors = manualErrors.map(me => {
    const question = Questions.find(q => q.id === me.questionId)
    return {
      pageId: question.page,
      ...me,
    }
  })
  const errorGroups = groupByArray(errors, 'pageId')

  if (manualErrors.length <= 0) {
    return null
  }

  return <PopoverRoot
    open={isShowingErrors}
    onOpenChange={() => setIsShowingErrors(!isShowingErrors)}
  >
    <PopoverTrigger asChild={true}>
      <ErrorsBadge noErrors={manualErrors.length === 0}>
        {manualErrors.length === 0 ? 'No ' : manualErrors.length} error{manualErrors.length !== 1 && 's'}
      </ErrorsBadge>
    </PopoverTrigger>
    <PopoverContent>
      <PopoverArrow />
  
      {errorGroups.length > 0 ?
        errorGroups.map(errorGroup => {
          return <Error key={errorGroup.key} to={`/build/${errorGroup.key}`}>
            {errorGroup.key} - {errorGroup.values.length} error{errorGroup.values.length !== 1 && 's'}
          </Error>
        })
      :
        <Success>
          {/* <SuccessIcon /> */}
          Everything looks good!
        </Success>
      }

    </PopoverContent>
  </PopoverRoot>

}

const ErrorsBadge = styled.button`
  border-radius: 100px;
  padding: .25em 1em;
  font-family: inherit;
  font-weight: ${p => p.noErrors ? 'normal' : '500'};
  background:  ${p => p.noErrors ? 'white' : 'var(--oc-red-1)'};
  color:       ${p => p.noErrors ? 'var(--text-secondary)' : 'var(--destructive)'};
  font-size: var(--s);
  display: inline-block;
  border: none;
  opacity: .8;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`
const Error = styled(Link)`
  padding: .25rem;
  border-radius: 4px;
  display: grid;
  grid-template-columns: auto max-content;
  grid-column-gap: 1rem;
  text-decoration: none;
  cursor: pointer;
  /* opacity: .8; */
  color: var(--destructive);
  font-weight: 500;
  font-size: var(--m);

  &:hover {
    background: hsl(0, 80%, 60%, .1);
    opacity: 1;
  }
`
const Success = styled.div`
  padding: 3rem;
`

export default Errors
