import { useState } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import produce from 'immer'

import { 
  SortableListWrapper,
  Group,
  Adder,
} from '../../styles/Sortables'
import {
  Dropdown,
  DropdownTrigger,
  DropdownContent,
  // DropdownLabel,
  DropdownItem,
  // DropdownGroup,
  // DropdownCheckbox,
  // DropdownIndicator,
  // DropdownRadioGroup,
  // DropdownRadioItem,
  DropdownSeparator,
  // DropdownArrow,
} from '../../components/DropdownMenu'
import {
  DialogRoot,
} from '../../components/Dialog'
import ListBuilderItem from './components/ListBuilderItem'
import ListBuilderModal from './components/ListBuilderModal'
import {
  QuestionWrapper,
  QuestionDescription,
  Example,
} from '../../styles/Questions'

// https://egghead.io/lessons/react-move-items-between-columns-with-react-beautiful-dnd-using-ondragend
// https://github.com/immerjs/immer

const QuestionBuilder = ({
  question,
  formData,
  saveFormData,
}) => {

  const { id, title, description, example, suggestions, EditorForm } = question

  const [ customIsOpen, setCustomIsOpen ] = useState(false)

  // TODO - pass this in from question data
  const typeOfThing = id === 'item_attributes' ? 'attribute' : id === 'item_actions' ? 'action' : 'item'

  const allAnswers = formData[id] || []

  const arrayOfAnswerIds = allAnswers.map(ag => ag.items).flat().map(a => a.id)
  const filteredSuggestions = suggestions && suggestions.filter(s => !arrayOfAnswerIds.includes(s.id))

  const onDragEnd = ({ source, destination }) => {
    
    // dropped outside a list
    if (!destination) {
      return
    }
    
    // source and destination may be the same (still works fine)
    saveFormData(produce(formData, formDataDraft => {
      const questionData = formDataDraft[id]
      const sourceGroup = questionData.find(group => group.id === source.droppableId)
      const destinationGroup = questionData.find(group => group.id === destination.droppableId)
      const sourceAnswers = sourceGroup.items || []
      const destinationAnswers = destinationGroup.items || []
      const [ removed ] = sourceAnswers.splice(source.index, 1)
      destinationAnswers.splice(destination.index, 0, removed)
    }))
  }

  const addItem = (itemId, groupId) => {
    if (itemId === '_nothing') {
      return
    } else if (itemId === '_custom') {
      alert(`TODO - form to add a custom ${typeOfThing}`)
    } else {
      const newOne = suggestions.find(s => s.id === itemId)
      saveFormData(produce(formData, formDataDraft => {
        const questionData = formDataDraft[id]
        const group = questionData.find(group => group.id === groupId)
        group.items.push(newOne)
      }))
    }
  }

  return <QuestionWrapper>
    {title && <h4>{ title(formData) }</h4>}
    {description && <QuestionDescription>{ description(formData) }</QuestionDescription> }
    {example && <Example>{ example }</Example> }

    <DragDropContext onDragEnd={onDragEnd}>
      {allAnswers && allAnswers.map(answerGroup => {
        return <Group key={answerGroup.id}>
          {allAnswers.length > 1 && <p>{answerGroup.title}</p> }
          <Droppable droppableId={answerGroup.id}>
            {({ droppableProps, innerRef, placeholder }, snapshot) => (
              <SortableListWrapper {...droppableProps} ref={innerRef} isDraggingOver={snapshot.isDraggingOver}>
                {answerGroup.items && answerGroup.items.map( (answer, index) => {
                  return <ListBuilderItem
                    key={answer.id}
                    answer={answer}
                    index={index}
                    EditorForm={EditorForm}
                  />
                })}
                {placeholder}
              </SortableListWrapper>
            )}
          </Droppable>

          <Dropdown>
            <DropdownTrigger asChild={true}>
              <Adder>
                Add an {typeOfThing}...
              </Adder>
            </DropdownTrigger>

            <DropdownContent sideOffset={4}>
              {filteredSuggestions && filteredSuggestions.map(suggestion => {
                return <DropdownItem key={suggestion.id} onSelect={() => addItem(suggestion.id, answerGroup.id)}>
                  {suggestion.title}
                </DropdownItem>
              })}
              { filteredSuggestions && filteredSuggestions.length > 0 && <DropdownSeparator /> }
              <DropdownItem key="custom_attribute" onSelect={() => setCustomIsOpen(true)}>Custom attribute</DropdownItem>
            </DropdownContent>
          </Dropdown>

        </Group>
      })}
    </DragDropContext>
    
    <DialogRoot
      id={'_custom'}
      open={customIsOpen}
      onOpenChange={() => setCustomIsOpen(!customIsOpen)}
      modal={true}
    >
      <ListBuilderModal answer={'_custom'} EditorForm={EditorForm} />
    </DialogRoot>

  </QuestionWrapper>
}

export default QuestionBuilder
