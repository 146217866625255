import styled from 'styled-components/macro'

import {
  AppNav,
  NavItem,
  NavIcon,
  NavText,
  EmailWrapper,
} from '../../styles/Previews'

const PreviewNotifications = ({ formData }) => {
  const {
    notifications_platform,
  } = formData

  const app = notifications_platform && notifications_platform.includes('in_app')
  const email = notifications_platform && notifications_platform.includes('email')

  if (notifications_platform && notifications_platform.length === 0) {
    return '(no notifications)'
  }

  return <PreviewNotificationsWrapper>
    {app &&
      <AppNav>
        <div></div>
        <div>
          <NavItem className="active">
            <NavIcon>
              <span className="iconify" data-icon={`mdi-bell`} />
            </NavIcon>
            <NavText>Notifications</NavText>
          </NavItem>
        </div>
      </AppNav>
    }

    {email &&
      <EmailWrapper>
        New notification...
      </EmailWrapper>
    }
  </PreviewNotificationsWrapper>
}

const PreviewNotificationsWrapper = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 1rem;
  justify-items: center;
`

export default PreviewNotifications
