import { useState } from 'react'
import Payment from './Payment'
import styled from 'styled-components/macro'

import { Button } from '../styles/Buttons'
import {
  DialogRoot,
  DialogPortal,
  DialogOverlay,
  DialogContent,
  DialogClose,
} from '../components/Dialog'
import SignupForm from './marketing/SignupForm'

const Purchase = ({
  formData,
  isPurchasing,
  setIsPurchasing,
  manualErrors,
}) => {

  const [ isPaying, setIsPaying ] = useState(false)

  return <>
    <DialogRoot
      id={'purchasing'}
      open={isPurchasing}
      onOpenChange={() => setIsPurchasing(!isPurchasing)}
      modal={true}
    >
      <DialogPortal>
        <DialogOverlay />
        <DialogContent width="860px">
          <DialogClose>✕</DialogClose>

          {/* <DialogTitle>Buy this design</DialogTitle> */}

          { manualErrors.length > 0 &&
            <div style={{color: 'red'}}>Please fix errors before purchasing</div>
          }

          {/* <p style={{fontStyle: 'italic'}}>Note: your configuration is currently not saved. Closing your browser will reset your data.</p> */}

          <PaymentColumns>

            <div>
              <p>For seed-stage startups</p>
              <h2>Download your design</h2>

              <p>Your design includes:</p>
              <ul>
                <li>A <strong>full UX design</strong> for your app as a downloadable, sharable Figma file (.fig)</li>
                <li>All the details: error states, empty states, onboarding, settings, and more</li>
                <li>A custom-generated <strong>design system</strong> of branded UI components to make future design consistent and easy</li>
                <li>Bundled fonts, icon packs, and all other necessary design <strong>assets</strong></li>
              </ul>
              {/* <p>You'll also receive:</p>
              <ul>
              <li><strong>Notes</strong> for engineers so they build everything to spec</li>
                <li>Premium video guides to help you make edits if you need to</li>
                <li>Instructions to help engineers install fonts and export colors</li>
                <li>Guidelines for integrating your design with Tailwind, Material Design, and other popular UI libraries</li>
                <li>Unlimited edits and re-downloads in Reflow for an entire year</li>
                <li>Access to our network of expert freelance UX designers if you need to make custom changes</li>
              </ul> */}

              <SmallText>
                Designs are delivered by email within 72 hours.
                Comes with our 30-day money-back guarantee.
                See Terms and Conditions for refund details.
              </SmallText>

              {/* <p>Special launch price</p> */}
              <Price><strike>$1200</strike>{' '}<strong>$695</strong></Price>
              {/* <Price>$695</Price> */}
              {/* <p>Hiring an experienced designer to build an entire application would normally cost $20k-$100k - <strong>that's 95% off</strong></p> */}

              <SignupForm $full />
              {/* <Button $wide onClick={() => setIsPaying(true)} autoFocus>Buy now</Button> */}
            </div>

            <div>
              <p>For Series-A and later</p>
              <h2>Hire a UX pro</h2>
              <p>Includes everything in the startup package, plus:</p>
              <ul>
                <li>Work with a <strong>dedicated design partner</strong> with a decade of experience in venture-backed, high-growth startups</li>
                <li>A <strong>UX strategy workshop</strong> to get your entire team aligned on goals</li>
                <li>Get a completely <strong>custom design</strong> for every part of your application</li>
                <li><strong>Unlimited design revisions</strong> for a single flat fee</li>
              </ul>

              <SmallText>
                Availability is limited.
                Early designs are usually delivered within two weeks.
                Requires separate Master of Services agreement.
              </SmallText>

              <Price>Price varies</Price>

              <Button
                $wide
                $secondary
                as="a"
                href="https://sampiercelolla.com"
                target="_blank"
              >
                Book a call
              </Button>
            </div>

          </PaymentColumns>

        </DialogContent>
      </DialogPortal>
    </DialogRoot>

    <Payment
      formData={formData}
      isPaying={isPaying}
      setIsPaying={setIsPaying}
    />

  </>
}

const PaymentColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 1rem;
  grid-column-gap: 3rem;
  /* margin-top: 2rem; */

  @media(max-width: 700px) {
    grid-template-columns: auto;
  }
`
const Price = styled.p`
  font-size: var(--l);
  margin: 2rem 0;
`
const SmallText = styled.p`
  font-size: var(--s);
  color: var(--text-secondary);
`

export default Purchase
