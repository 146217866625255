import * as Popover from '@radix-ui/react-popover'
import styled from 'styled-components/macro'

export const PopoverRoot = styled(Popover.Root)`
`
export const PopoverTrigger = styled(Popover.Trigger)`
`
export const PopoverAnchor = styled(Popover.Anchor)`
`
export const PopoverContent = styled(Popover.Content)`
  display: block;
  width: ${p => p.width ? p.width : '300px'};
  max-width: 100%;
  max-height: 100%;

  padding: 1rem;
  background: white;
  box-shadow: var(--shadow-elevation-high);
  border-radius: 4px;
  z-index: 120;

  overflow-y: auto;
`
export const PopoverClose = styled(Popover.Close)`
  appearance: none;
  border: none;
  position: absolute;
  right: 1rem;
  top: 1rem;
  border-radius: 100px;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  font-size: 20px;
  line-height: 1;
  background: white;

  &:hover {
    background: var(--hover);
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--input-border);
  }
`
export const PopoverArrow = styled(Popover.Arrow)`
  fill: white;
`
