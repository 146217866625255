import NavSecondary from './components/NavSecondary'
import NavMenu from './components/NavMenu'
import {
  AppNav,
} from '../../styles/Previews'

const PreviewNavOther = ({ formData }) => {
  return <AppNav>
    <div />
    <NavSecondary formData={formData} />
    <NavMenu formData={formData} />
  </AppNav>
}

export default PreviewNavOther
