import { Link } from 'react-router-dom'
// import { useState } from 'react'

import { Button } from '../../styles/Buttons'
import {
  Section,
  Container,

  // HeroBulletin,
  HeroHeading,
  // HeroSubheading,
  HeroParagraph,
  HeroWrapper,
  HeroObjections,

  ColorBg,

  // FeaturesWrapper,
  // FeaturesBlock,

  // Eyebrow,
  // FeaturesHeading,
  // FeaturesText,
  // FeaturesImage,

  // SmallHeroWrapper,
  // SmallHeroHeading,
  // SmallHeroSub,
  // SmallHeroObjections,
  
  FaqWrapper,
  FaqItem,
  FaqTarget,
  FaqQuestion,
  FaqAnswerContainer,
  FaqAnswer,
  
  // BigHeadingWrapper,
  // BigHeadingHeading,
  // BigHeadingSub,

  SmallHeadingWrapper,
  SmallHeadingHeading,
  SmallHeadingSub,

  ArticleListWrapper,
  ArticleListItem,
  ArticleListThumb,
  ArticleListTitle,
  // ArticleListSub,
  
} from '../../styles/Marketing'
// import { Button } from '../../styles/Buttons'
import Header from './Header'
import Footer from './Footer'
// import Gradient from './Gradient'

const Home = () => {

  return <>
    <Header />

    <Section>
      <Container>
        <HeroWrapper>
          {/* <HeroBulletin>Private beta</HeroBulletin> */}
          <HeroHeading>Can AI replace UX designers?</HeroHeading>
          {/* <HeroSubheading>(You might be surprised...)</HeroSubheading> */}
          <HeroParagraph>You might be surprised. Our automated design engine generates pixel-perfect, full-featured app designs—<span>no&nbsp;design&nbsp;skills&nbsp;needed</span>.</HeroParagraph>
          <Button $large as={Link} to="/build">Try the demo →</Button>
          <HeroObjections>No email or credit card required</HeroObjections>
        </HeroWrapper>
      </Container>
    </Section>

    {/* <Gradient /> */}

    {/* <Section>
      <Container>

        <FeaturesWrapper>
          <FeaturesBlock>
            <FeaturesImage />
          </FeaturesBlock>
          <FeaturesBlock>
            <Eyebrow>For customers</Eyebrow>
            <FeaturesHeading>Create a world-class user experience</FeaturesHeading>
            <FeaturesText>
              <p>These days, founders can't afford to compromise on design.</p>
              <p>Users expect apps to be easy to use, fast to navigate, and modern, clean, and friendly.</p>
              <p>Luckily great software UX doesn't have to be complicated.</p>
              <p>Reflow leans on years of best practices to create an app that just works. We help you avoid common design mistakes, keep users engaged, and untangle complexity.</p>
            </FeaturesText>
          </FeaturesBlock>
        </FeaturesWrapper>
        
        <FeaturesWrapper>
          <FeaturesBlock>
            <FeaturesImage />
          </FeaturesBlock>
          <FeaturesBlock>
            <Eyebrow>For founders</Eyebrow>
            <FeaturesHeading>No design skill required</FeaturesHeading>
            <FeaturesText>
              <p>Just answer a few questions about your business and let us do the rest.</p>
              <p>No dragging or dropping components, no templates, and no writing. We take care of all that for you.</p>
              <p>Just answer some high-level questions that you already know, like who your users are and what kind of data you want to show them. Reflow will handle the rest, automatically.</p>
            </FeaturesText>
          </FeaturesBlock>
        </FeaturesWrapper>

        <FeaturesWrapper>
          <FeaturesBlock>
            <FeaturesImage />
          </FeaturesBlock>
          <FeaturesBlock>
            <Eyebrow>For startups</Eyebrow>
            <FeaturesHeading>Less time, less money, less pain</FeaturesHeading>
            <FeaturesText>
              <p>As a founder, it's your job to get to product-market fit as fast as possible.</p>
              <p>You may not be a designer, but you know what your customers want. With Reflow, you have access to new world of speed and control.</p>
              <p>Iterate through ideas as quick as you have them. Unblock engineers and start shipping immediately.</p>
            </FeaturesText>
          </FeaturesBlock>
        </FeaturesWrapper>

      </Container>
    </Section> */}
    {/* <Section>
      <Container>

        <SmallHeroWrapper>
          <SmallHeroHeading>Bringing your startup vision to life, risk free</SmallHeroHeading>
          <SmallHeroSub>Answer a few simple questions and see your design come to life. Don't pay unless you love the results.</SmallHeroSub>
          <Button as={Link} to="/build">Design your app</Button>
          <SmallHeroObjections>Try for free · No credit card required</SmallHeroObjections>
        </SmallHeroWrapper>

      </Container>
    </Section> */}
    <Section>
      <ColorBg />
    </Section>
    <Section>
      <Container>

        <FaqWrapper type="single" collapsible={true}>

          <FaqItem value="cost">
            <FaqTarget>
              <FaqQuestion>How much does it cost?</FaqQuestion>
            </FaqTarget>

            <FaqAnswerContainer>
              <FaqAnswer>
                <p>During launch, Reflow plans start at $495—a tiny fraction of a design pro. That means you can save your money, extend your runway, and give yourself more time to talk to customers.</p>
                <p>Don't spend tens of thousands of dollars on a professional designer.</p>
              </FaqAnswer>
            </FaqAnswerContainer>
          </FaqItem>
          
          <FaqItem value="work">
            <FaqTarget>
              <FaqQuestion>How does it work?</FaqQuestion>
            </FaqTarget>

            <FaqAnswerContainer>
              <FaqAnswer>
                <p>Reflow uses a custom-built engine to generate designs for customers.</p>
                <p>Under the hood, it's primarily using heuristic algorithms to generate UI, which are based on a proprietary UX design methodology we've developed with clients. While we're in beta, there are some human-in-the-loop validation steps to make sure all designs meet our bar for quality.</p>
                <p>If that doesn't make any sense to you, just remember this:&nbsp;Reflow is smart, but based on expert designers' experience—and you're still in full control.</p>
              </FaqAnswer>
            </FaqAnswerContainer>
          </FaqItem>
          
          <FaqItem value="time">
            <FaqTarget>
              <FaqQuestion>How long does it take?</FaqQuestion>
            </FaqTarget>

            <FaqAnswerContainer>
              <FaqAnswer>
                <p>We send most designs within 24 hours.</p>
                <p>Designs are note delivered instantly because real humans review each design before sending it to customers to ensure quality while we're in beta.</p>
              </FaqAnswer>
            </FaqAnswerContainer>
          </FaqItem>
          
          <FaqItem value="designer">
            <FaqTarget>
              <FaqQuestion>What if I'm not a designer?</FaqQuestion>
            </FaqTarget>

            <FaqAnswerContainer>
              <FaqAnswer>
                <p>That's perfect! Reflow is made for non-designers.</p>
                <p>If you're a founder, investor, product manager, or engineer, you'll have no trouble using the tool. There's no dragging and dropping—just answer a few high-level questions about your business and we take care of the rest.</p>
              </FaqAnswer>
            </FaqAnswerContainer>
          </FaqItem>
          
          <FaqItem value="mobile">
            <FaqTarget>
              <FaqQuestion>Can I generate mobile designs?</FaqQuestion>
            </FaqTarget>

            <FaqAnswerContainer>
              <FaqAnswer>
                <p>Currently, Reflow creates designs for responsive web, including desktop, tablet, and mobile sizes.</p>
                <p>Support for native mobile apps is coming soon.</p>
              </FaqAnswer>
            </FaqAnswerContainer>
          </FaqItem>
          
          <FaqItem value="return">
            <FaqTarget>
              <FaqQuestion>What if I don't like the result?</FaqQuestion>
            </FaqTarget>

            <FaqAnswerContainer>
              <FaqAnswer>
                <p>You never have to pay until you're 100% satisfied.</p>
                <p>In fact, you can generate and download full designs with Reflow without paying a single cent. We retain all the intellectual property to the design until you pay, so you don't have to buy anything until you love the design.</p>
              </FaqAnswer>
            </FaqAnswerContainer>
          </FaqItem>
          
          <FaqItem value="cost">
            <FaqTarget>
              <FaqQuestion>How do I code my designs?</FaqQuestion>
            </FaqTarget>

            <FaqAnswerContainer>
              <FaqAnswer>
                <p>Reflow is a design-only service. Once you have the design, building your app is up to you.</p>
                <p>If you work with engineers, have them try Reflow. They'll be used to the kind of designs Reflow generates. Our components are designed to be similar to component libraries like Tailwind and AntD to make the transition as easy as possible.</p>
                <p>If you don't have engineers on your team, you can use no-code tools like Bubble or Retool to make your app yourself.</p>
              </FaqAnswer>
            </FaqAnswerContainer>
          </FaqItem>

        </FaqWrapper>

      </Container>
    </Section>
    <Section>
      <Container>

        <SmallHeadingWrapper>
          <SmallHeadingHeading>The future of design automation is here</SmallHeadingHeading>
          <SmallHeadingSub>Learn how founders are leveraging new tools to move faster than ever</SmallHeadingSub>
        </SmallHeadingWrapper>

        <ArticleListWrapper>
          <ArticleListItem href="#">
            <ArticleListThumb src="" />
            <ArticleListTitle>Is UX design dead?</ArticleListTitle>
            {/* <ArticleListSub>sdfsdfsdfsd</ArticleListSub> */}
          </ArticleListItem>
          <ArticleListItem href="#">
            <ArticleListThumb src="" />
            <ArticleListTitle>What every founder needs to know about UX</ArticleListTitle>
            {/* <ArticleListSub>sdfsdfsdfsd</ArticleListSub> */}
          </ArticleListItem>
          <ArticleListItem href="#">
            <ArticleListThumb src="" />
            <ArticleListTitle>Design on the cheap</ArticleListTitle>
            {/* <ArticleListSub>sdfsdfsdfsd</ArticleListSub> */}
          </ArticleListItem>
        </ArticleListWrapper>

      </Container>
    </Section>

    <Footer />
  </>
}

export default Home
