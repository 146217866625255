import * as Dialog from '@radix-ui/react-dialog'
import styled from 'styled-components/macro'

export const DialogRoot = styled(Dialog.Root)`
`
export const DialogTrigger = styled(Dialog.Trigger)`
`
export const DialogPortal = styled(Dialog.Portal)`
`
export const DialogOverlay = styled(Dialog.Overlay)`
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background: var(--modal-background);
  top: 0;
  left: 0;
`
export const DialogContent = styled(Dialog.Content)`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  display: ${p => p.$wizard && 'flex'};
  width: ${p => p.width ? p.width : p.$wizard ? '1000px' : '500px'};
  max-width: 100%;
  min-height: ${p => p.$wizard ? '500px' : '300px'};
  max-height: 100%;

  padding: ${p => p.$wizard ? '0' : '2rem'};
  background: white;
  box-shadow: var(--shadow-elevation-high);
  border-radius: 4px;
  z-index: 120;

  overflow-y: auto;
`
export const DialogTitle = styled(Dialog.Title)`
  margin: 0 0 1rem;
`
export const DialogDescription = styled(Dialog.Description)`
`
export const DialogClose = styled(Dialog.Close)`
  appearance: none;
  border: none;
  position: absolute;
  right: 1rem;
  top: 1rem;
  border-radius: 100px;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  font-size: 20px;
  line-height: 1;
  background: white;

  &:hover {
    background: var(--hover);
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--input-border);
  }
`
