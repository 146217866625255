import {
  QuestionWrapper,
  QuestionTitle,
  QuestionDescription,
  Example,
  QuestionError,
} from '../../styles/Questions'
import { Input } from '../../styles/Forms'

const QuestionBigText = ({
  register,
  question,
  formData,
  errors,
}) => {

  const { id, title, description, example } = question

  return <QuestionWrapper>
    <QuestionTitle>{ title(formData) }</QuestionTitle>
    {description && <QuestionDescription>{ description(formData) }</QuestionDescription> }
    {example && <Example>{ example }</Example> }
    <Input type="text" name={id} {...register(id)} />
    {errors[id] && <QuestionError>This field is required</QuestionError>}
  </QuestionWrapper>
}

export default QuestionBigText
