import {
  Section,
  Container,

  HeaderWrapper,
  LogoWrapper,
  LogoText,
} from '../../styles/Marketing'
import SignupForm from './SignupForm'

const Header = () => {

  return <Section>
    <Container $full>
      <HeaderWrapper>
        <LogoWrapper>
          <LogoText>
            Reflow
          </LogoText>
        </LogoWrapper>
        <div>
          <SignupForm $secondary />
        </div>
      </HeaderWrapper>
    </Container>
  </Section>
}

export default Header
