import ArrowDown from '../../svg/previews/arrow_down.svg'
import {
  PageFlowWrapper,
  PageFlowArrow,
  PageFlowPoint,
  EmailWrapper,
  EmailSubject,
  EmailBody,
} from '../../styles/Previews'

const PreviewEmail = ({ formData }) => {
  const {
    app_name,
    email_types,
  } = formData

  const emailContent = {
    welcome_email: {
      subject: `Welcome to ${app_name || 'MyApp'}`,
      body: `Hi, I'm Jay, reach out anytime if you need help`,
    },
    how_to: {
      subject: `Getting started with ${app_name || 'MyApp'}`,
      body: `Get the most out of your account by setting up your integrations`,
    },
    demo_invite: {
      subject: `Join us for a free demo`,
      body: `Join us next week for a 30m tour with our head of sales!`,
    },
    case_studies: {
      subject: `Learn how our customers are transforming...`,
      body: `Learn how Google, Netflix, and Shopify all increased employee retention`,
    },
    trial_expiring: {
      subject: `Your trial will expire in 3 days`,
      body: `Upgrade to keep the best features of  ${app_name || 'MyApp'}`,
    },
    customer_welcome: {
      subject: `Thanks for being a customer!`,
      body: `Welcome! Here's your complete setup kit`,
    },
    usage_review: {
      subject: `Your weekly ${app_name || 'MyApp'} report`,
      body: `Activity was up X% this week...`,
    },
    survey: {
      subject: `Do you have 5 minutes to help us improve ${app_name || 'MyApp'}?`,
      body: `Answer a few questions for a chance at a $100 Amazon gift card...`,
    },
  }

  if (!email_types || email_types.length < 1) {
    return '(no drip emails)'
  }

  return <PageFlowWrapper>
    <PageFlowPoint>Sign up</PageFlowPoint>
    <PageFlowArrow src={ArrowDown} />
    {email_types?.map( (et, index) => {
      const {subject, body} = emailContent[et]
      return <>
        <EmailWrapper key={et}>
          <EmailSubject>{subject}</EmailSubject>
          <EmailBody>{body}</EmailBody>
        </EmailWrapper>
        { index !== email_types.length - 1 &&
          <PageFlowArrow src={ArrowDown} />
        }
      </>
    })}
  </PageFlowWrapper>
}

export default PreviewEmail
