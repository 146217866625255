import NavSecondary from './components/NavSecondary'
import NavMain from './components/NavMain'
import {
  AppBody,
  AppNav,
  AppMain,
  ContentBox,
} from '../../styles/Previews'

const PreviewNavType = ({ formData }) => {
  return <AppBody>

    <AppNav>
      <NavMain formData={formData} />
      <NavSecondary formData={formData} />
    </AppNav>

    <AppMain>
      <ContentBox />
      <ContentBox />
      <ContentBox />
      <ContentBox />
      <ContentBox />
      <ContentBox />
      <ContentBox />
    </AppMain>
  </AppBody>
}

export default PreviewNavType
