import getCollectionName from '../../helpers/getCollectionName'
import styled from 'styled-components/macro'

import {
  Page,
  PageHeader,
  ContentBox,
  Button,
} from '../../styles/Previews'

const PreviewEditing = ({ formData }) => {
  const {
    collection_name_plural,
    editing_yn,
    edit_autosave_yn,
  } = formData

  if (editing_yn !== 'editing_y') {
    return '(no editing)'
  }

  return <Page>
    <PageHeader>
      Edit {getCollectionName(collection_name_plural, { plural: false, capital: false})}
      { edit_autosave_yn === 'edit_autosave_y' && <p>Saved just now</p> }
    </PageHeader>

    <ContentBox />
    <ContentBox />
    <ContentBox />

    { edit_autosave_yn === 'edit_autosave_y' ?
      <ButtonRow>
        <Button>Done</Button>
      </ButtonRow>
      :
      <ButtonRow>
        <Button $secondary>Cancel</Button>
        <Button>Save</Button>
      </ButtonRow>
    }
  </Page>
}

const ButtonRow = styled.div`
  display: grid;
  grid-gap: .5rem;
  grid-template-columns: max-content max-content;
  justify-content: end;
  margin: 1.5rem 0 -.5rem;
`

export default PreviewEditing
