import styled from 'styled-components/macro'

import NavSecondary from './components/NavSecondary'
import NavMain from './components/NavMain'
import {
  AppMain,
  ContentBox,
  AppBody,
  AppNav,
} from '../../styles/Previews'

const PreviewDashboard = ({ formData }) => {
  const {
    dashboard_yn,
  } = formData

  const primary_items = [
    {
      id: `stats`,
      title: `Statistics around what's happened lately`,
    },
    {
      id: `chart`,
      title: `Chart`,
    },
  ]

  const secondary_items = [
    {
      id: `timeline`,
      title: `A timeline of recent events`,
    },
    {
      id: `top`,
      title: `Top or popular items`,
    },
  ]

  return <AppBody>

    <AppNav>
      <NavMain formData={formData} />
      <NavSecondary formData={formData} />
    </AppNav>

    <AppMain>
      {dashboard_yn === 'dashboard_y' ? 
      <DashboardWrapper>
        <h4>Welcome, Jamie</h4>
        <DashboardColumns>
          <DashboardColumnMain>
            {primary_items && primary_items.map(di => {
              return <Widget key={di.id}>{di.title}</Widget>
            })}
          </DashboardColumnMain>
          <DashboardColumnSecondary>
            {secondary_items && secondary_items.map(di => {
              return <Widget key={di.id}>{di.title}</Widget>
            })}
          </DashboardColumnSecondary>
        </DashboardColumns>
      </DashboardWrapper>
      :
      <>
        {/* <h4>{navItems ? navItems[0].title : 'Items'}</h4> */}
        <ContentBox />
        <ContentBox />
        <ContentBox />
      </>
      }
    </AppMain>
  </AppBody>
}

const DashboardWrapper = styled.div`
  display: grid;
  grid-template-rows: max-content auto;
  grid-row-gap: 1rem;
  height: 100%;
`
const DashboardColumns = styled.div`
  display: grid;
  grid-template-columns: 5fr 3fr;
  grid-column-gap: 1rem;
  min-height: 400px; // for horizontal nav
`
const DashboardColumnMain = styled.div`
  display: flex;
  flex-direction: column;
`
const DashboardColumnSecondary = styled.div`
  display: flex;
  flex-direction: column;
  `
const Widget = styled.div`
  border-radius: 4px;
  min-height: 80px;
  border: 1px solid var(--input-border);
  padding: .5rem;
  margin: 0 0 1rem 0;
  flex: 1; /* fill available space */
  
  background: white;
  box-shadow: ${p => p.theme.grayBg && '0 2px 4px #eee'};

  /* just for now */
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`

export default PreviewDashboard
