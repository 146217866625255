import styled from 'styled-components/macro'
import { collection, addDoc, getFirestore } from "firebase/firestore"

import { Button } from '../../styles/Buttons'
import { Input } from '../../styles/Forms'

const SignupForm = ({
  $full,
  $secondary,
}) => {

  const onSubmit = async event => {
    try {
      const email = event.target.value
      debugger
      const db = getFirestore()
      await addDoc(collection(db, "email_signups"), { email })
      alert("Thank you for signing up! We'll let you know when we're ready to launch :)")
    } catch (error) {
      alert(`Whoops--we couldn't sign you up right now, try again in a minute?`)
      console.error("Error signing up user: ", error)
    }
  }
  
  return <FormWrapper onSubmit={onSubmit} $full={$full}>
    <Input
      name="email"
      type="email"
      placeholder="your@email.com"
    />
    <Button
      type="submit"
      $secondary={$secondary}
      $wide={$full}
    >
      Join waitlist
    </Button>
  </FormWrapper>
}

const FormWrapper = styled.p`
  display: grid;
  grid-template-columns: ${p => p.$full ? 'auto' : 'max-content max-content'};
  grid-column-gap: 1rem;
  grid-row-gap: .5rem;

  margin: 0;
`

export default SignupForm
