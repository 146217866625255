import NavMain from './components/NavMain'
import {
  AppNav,
} from '../../styles/Previews'

const PreviewNavMain = ({ formData }) => {
  return <AppNav>
    <NavMain formData={formData} />
    <div />
  </AppNav>
}

export default PreviewNavMain
