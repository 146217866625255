// import { Link } from 'react-router-dom'
// import { Button } from '../../styles/Buttons'
import {
  Section,
  Container,

  FinalCtaWrapper,
  FinalCtaHeading,
  FinalCtaSub,

  FooterWrapper,
  // FooterHeading,
  // FooterLink,
  
  SocialIconsWrapper,
  // SocialIconsLink,
  // SocialIconsImage,

  BasementText,
} from '../../styles/Marketing'
import SignupForm from './SignupForm'
// import TwitterIcon from '../../svg/social/twitter.svg'
// import LinkedInIcon from '../../svg/social/linkedin.svg'
// import YoutubeIcon from '../../svg/social/youtube.svg'

const Footer = () => {

  return <Section>
    <Container>

      <FinalCtaWrapper>
        <div>
          <FinalCtaHeading>Try the free beta</FinalCtaHeading>
          <FinalCtaSub>Sign up to be the first in line when we launch publicly. No spam, unsubscribe anytime.</FinalCtaSub>
        </div>
        <SignupForm />
      </FinalCtaWrapper>

      <FooterWrapper>
        <div>
          {/* <FooterHeading>Reflow</FooterHeading>
          <FooterLink>Home</FooterLink>
          <FooterLink>Features</FooterLink>
          <FooterLink>Pricing</FooterLink>
          <FooterLink>About</FooterLink> */}
        </div>
        <div>
          {/* <FooterHeading>Support</FooterHeading>
          <FooterLink>Tutorials</FooterLink>
          <FooterLink>Knowledge base</FooterLink>
          <FooterLink>Contact us</FooterLink> */}
        </div>
        <div>
          {/* <FooterHeading>Use cases</FooterHeading>
          <FooterLink>Startups</FooterLink>
          <FooterLink>Enterprise</FooterLink>
          <FooterLink>Agencies</FooterLink>
          <FooterLink>No-code</FooterLink> */}
        </div>
        <div>
          <SocialIconsWrapper>
            {/* <SocialIconsLink href="https://twitter.com/sampleite">
              <SocialIconsImage src={TwitterIcon} />
            </SocialIconsLink>
            <SocialIconsLink href="https://linkedin.com/in/sampiercelolla">
              <SocialIconsImage src={LinkedInIcon} />
            </SocialIconsLink> */}
            {/* <SocialIconsLink href="https://youtube.com/">
              <SocialIconsImage src={YoutubeIcon} />
            </SocialIconsLink> */}
          </SocialIconsWrapper>
        </div>
      </FooterWrapper>

      <BasementText>
        &copy; <a href="https://directedworks.com/" rel="noreferrer" target="_blank">2022 Directed Works LLC</a>
        {/* {' · '}
        Terms
        {' · '}
        Privacy */}
      </BasementText>

    </Container>
  </Section>
}

export default Footer
