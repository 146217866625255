import {
  QuestionWrapper,
  QuestionTitle,
  QuestionDescription,
} from '../../styles/Questions'

const QuestionUnknown = ({
  question,
  formData,
}) => {

  const { title, description } = question

  return <QuestionWrapper>
    <QuestionTitle>{ title(formData) }</QuestionTitle>
    {description && <QuestionDescription>{ description(formData) }</QuestionDescription> }
    <div style={{color: 'red'}}>Whoops, there was an error with this question. Sorry!</div>
  </QuestionWrapper>
}

export default QuestionUnknown
