import styled from 'styled-components/macro'

import {
  Page,
  PageHeader,
  Button,
} from '../../styles/Previews'

const PreviewPricing = ({ formData }) => {
  const {
    charge_yn,
    free_tier_yn,
    pricing_free_trial_yn,
  } = formData

  const trial = pricing_free_trial_yn === 'pricing_free_trial_y'

  if (charge_yn === 'charge_n') {
    return `(no pricing)`
  }

  return <Page>
    <PageHeader>Choose plan</PageHeader>

    <TierWrapper>
      { free_tier_yn === 'free_tier_y' ?
        <Tier>
          Free
          <Button $secondary>Current plan</Button>
        </Tier>
        :
        <Tier>
          $▮▮▮▮
          <Button $secondary>Current plan</Button>
        </Tier>
      }
      <Tier>
        $▮▮▮▮
        <Button>{trial ? 'Start trial' : 'Upgrade'}</Button>
      </Tier>
      <Tier>
        $▮▮▮▮
        <Button>{trial ? 'Start trial' : 'Upgrade'}</Button>
      </Tier>
    </TierWrapper>
  </Page>
}

const TierWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1rem;
`
const Tier = styled.div`
  border: 1px solid var(--hairline);
  margin: 0 0 1rem;
  border-radius: 4px;
  padding: 1rem;
  font-size: var(--l);
  font-weight: 500;
  box-shadow: 0 2px 4px var(--shadow);
`

export default PreviewPricing
