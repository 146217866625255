import {
  AppNavSecondaryWrapper,
  NavItem,
  NavIcon,
  NavText,
  PopupAvatar,
  NavAccount,
  SearchInput,
} from '../../../styles/Previews'
import { NavItems } from '../data/navItems'

const NavSecondary = ({ formData }) => {
  const {
    nav_other_pages,
    navigation_type_picker,
    search_yn,
  } = formData

  const allItems = nav_other_pages && nav_other_pages.map(i => NavItems[i])
  const items = allItems && allItems.filter(i => i.emphasized)

  return <AppNavSecondaryWrapper>
    {
      search_yn === 'search_y' &&
      navigation_type_picker === 'horizontal' &&
      <SearchInput type="search" placeholder="Search..." />
    }

    {items && items.map( (item, index) => {
      const { icon, label } = item
      return <NavItem key={label}>
        <NavIcon>
          <span className="iconify" data-icon={`mdi-${icon || 'square'}`} />
        </NavIcon>
        {navigation_type_picker !== 'horizontal' && <NavText>{label || 'Page'}</NavText>}
      </NavItem>
    })}

    <NavAccount>
      <PopupAvatar alt="profile pic" src="https://www.placebear.com/100/100" size={30} />
      {navigation_type_picker !== 'horizontal' && <strong>Jamie Doe</strong>}
    </NavAccount>
  </AppNavSecondaryWrapper>
}

export default NavSecondary
