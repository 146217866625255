import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import styled from 'styled-components/macro'

export const Dropdown = styled(DropdownMenu.Root)`
`
export const DropdownTrigger = styled(DropdownMenu.Trigger)`
`
export const DropdownContent = styled(DropdownMenu.Content)`
  border: 1px solid var(--hairline);
  background: white;
  box-shadow: var(--shadow-elevation-high);
  padding: .5rem;
  border-radius: 4px;
`
export const DropdownLabel = styled(DropdownMenu.Label)`
`
export const DropdownItem = styled(DropdownMenu.Item)`
  padding: .25rem .5rem;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background: var(--hover);
  }
`
export const DropdownGroup = styled(DropdownMenu.Group)`
`
export const DropdownCheckbox = styled(DropdownMenu.CheckboxItem)`
`
export const DropdownIndicator = styled(DropdownMenu.ItemIndicator)`
`
export const DropdownRadioGroup = styled(DropdownMenu.RadioGroup)`
`
export const DropdownRadioItem = styled(DropdownMenu.RadioItem)`
`
export const DropdownSeparator = styled(DropdownMenu.Separator)`
  height: 1px;
  background: var(--hairline);
  margin: .5rem;
`
export const DropdownArrow = styled(DropdownMenu.Arrow)`
`