import { useState, useEffect } from 'react'
import { object } from 'yup'

import Questions from '../data/questions'
import Pages from '../data/pages'
import { useParams } from 'react-router-dom'
import Wizard from './Wizard'
import Panel from './panel/Panel'
import Purchase from './Purchase'

const Builder = () => {

  const { currentPageId } = useParams()

  const [ formData, setFormData ] = useState({})
  const [ isPurchasing, setIsPurchasing ] = useState()

  const currentPage = Pages.find(p => p.id === currentPageId)
  
  const [ manualErrors, setManualErrors ] = useState([])

  // once errors pages have been loaded, we start showing error count in the nav
  // and we should start checking for error resolution whenever form data is updated
  // this is a one-way flag that saves whether or not error pages have been viewed
  const [ isCheckingForErrorsTripwire, setIsCheckingForErrorsTripwire ] = useState(false)

  const saveFormData = data => {
    setFormData({
      ...formData,
      ...data,
    })
  }

  // https://github.com/jquense/yup/issues/44
  // https://stackoverflow.com/a/56492329
  useEffect(() => {
    // why
    if (!currentPage) {
      return
    }

    if (!isCheckingForErrorsTripwire && currentPage.inWizard) {
      return
    }

    setIsCheckingForErrorsTripwire(true)

    const yupErrorValidations = Questions.reduce((result, question) => {
      if (!question.yup_validation) {
        return result
      }
      return {
        ...result,
        [question.id]: question.yup_validation,
      }
    }, {})
    const errorSchema = object(yupErrorValidations)
    return errorSchema.validate(formData, { abortEarly: false })
      .then( () => {
        setManualErrors([])
      })
      .catch(errors => {
        // https://github.com/jquense/yup/issues/44#issuecomment-761780927
        setManualErrors(errors.inner.map(e => {
          return {
            questionId: e.path,
            message: e.message,
          }
        }))
      })
  }, [formData, isCheckingForErrorsTripwire, currentPageId, currentPage])

  return <>
    <Wizard
      formData={formData}
      saveFormData={saveFormData}
    />

    {isPurchasing &&
      <Purchase
        manualErrors={manualErrors}
        formData={formData}
        isPurchasing={isPurchasing}
        setIsPurchasing={setIsPurchasing}
      />
    }

    <Panel
      manualErrors={manualErrors}
      formData={formData}
      saveFormData={saveFormData}
      setIsPurchasing={setIsPurchasing}
    />
  </>
}

export default Builder
