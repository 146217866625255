// https://stackoverflow.com/a/34890276
const groupByArray = (arr, key) => {
  return arr.reduce( (prev, current) => {
    let val = key instanceof Function ? key(current) : current[key]
    let group = prev.find(g => g && g.key === val)
    if (group) {
      group.values.push(current)
    } else {
      prev.push({ key: val, values: [current] })
    }
    return prev
  }, [])
}

export default groupByArray
