// TODO - icons
const PageGroups = {
  users: {
    title: `Users`,
    icon: `person`,
  },
  addons: {
    title: `Add-ons`,
    icon: `magic`,
  },
  navigation: {
    title: `Navigation`,
    icon: `ship`,
  },
  style: {
    title: `Style`,
    icon: `paint`,
  },
}

export default PageGroups
