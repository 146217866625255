export const Actions = {
  edit: {
    label: `Edit`,
    icon: `pencil`,
  },
  message: {
    label: `Message`,
    icon: `message`,
  },
  favorite: {
    label: `Favorite`,
    icon: `heart`,
  },
  share: {
    label: `Share`,
    icon: `link`,
  },
  copy_link: {
    label: `Copy link`,
    icon: `link`,
  },
  print: {
    label: `Print`,
    icon: `printer`,
  },
  open: {
    label: `Open`,
    icon: `open-in-new`,
  },
  follow: {
    label: `Follow`,
    icon: `add`,
  },
  rename: {
    label: `Rename`,
    icon: `account-edit-outline`,
  },
  duplicate: {
    label: `Duplicate`,
    icon: `plus-circle-multiple-outline`,
  },
  preview: {
    label: `Preview`,
    icon: `eye`,
  },
  download: {
    label: `Download`,
    icon: `download`,
  },
  export: {
    label: `Export`,
    icon: `export`,
  },
  lock: {
    label: `Lock`,
    icon: `lock`,
  },
  report: {
    label: `Report`,
    icon: `flag`,
  },
}
