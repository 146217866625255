import styled from 'styled-components/macro'
import MacBook from '../../svg/previews/MacBook.svg'

const PreviewAppName = ({ formData }) => {
  return <PreviewAppNameWrapper>
    <Image src={MacBook} />
    <AppLoading>
      <AppName>{formData.app_name}</AppName>
    </AppLoading>
  </PreviewAppNameWrapper>
}

const PreviewAppNameWrapper = styled.div`
  position: relative;
`
const Image = styled.img`
  width: 800px;
`
const AppLoading = styled.div`
  position: absolute;
  top: 94px;
  right: 132px;
  left: 157px;
  bottom: 105px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 2px;
  background: white;
`
const AppName = styled.div`
  padding: .5rem;
`

export default PreviewAppName
