import { Routes, Route, Navigate } from 'react-router-dom'
import Builder from './Builder'
import Home from './marketing/Home'

const Router = () => {

  return <Routes>
    <Route path="/build/:currentPageId" element={<Builder />} />
    <Route path="/" element={<Home />} />
    <Route path="/build" element={<Navigate to="/build/data_name" />} />
    <Route path="*" element={<Navigate to="/" />} />
  </Routes>
}

export default Router
