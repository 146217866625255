import { useRef } from 'react'
import styled, { ThemeProvider } from 'styled-components/macro'
import dots from '../svg/previews/dots.svg'

import useSize from '@react-hook/size'
import { Themes } from '../styles/Themes'

const Preview = ({
  currentPage,
  formData,
}) => {

  const PreviewComponent = currentPage?.PreviewComponent

  const padding = 50

  const outerTarget = useRef(null)
  const innerTarget = useRef(null)
  const [ outerWidth, outerHeight ] = useSize(outerTarget)
  const [ innerWidth, innerHeight ] = useSize(innerTarget)

  // TODO - maybe this isn't the best place to add the padding?
  const widthRatio  = (innerWidth  + padding) / outerWidth
  const heightRatio = (innerHeight + padding) / outerHeight
  const biggerRadio = Math.max(widthRatio, heightRatio)
  const scaleFactor = Math.min(1 / biggerRadio, 1)

  // console.log(`${outerWidth} x ${outerHeight} --vs-- ${innerWidth} x ${innerHeight}  \nwidthRatio: ${widthRatio} | heightRatio: ${heightRatio}   \nbigger: ${biggerRadio}  \nscaleFactor ${scaleFactor}`)

  // TODO - move these to separate Theme files like colors is? Or one big giant theme config file? Or bring colors in this file?
  const fontThemes = {
    simple: {
      fontHeading: 'Arial, sans-serif',
    },
    owners: {
      fontHeading: `'Krona One', sans-serif`,
    },
    garamond: {
      fontHeading: `'Lora', serif`,
    },
    clarendon: {
      fontHeading: `'Domine', serif`,
    },
  }

  const roundednessThemes = {
    not_rounded: {
      buttonRadius: `0px`,
      cardRadius: `0px`,
    },
    normal_rounded: {
      buttonRadius: `4px`,
      cardRadius: `6px`,
    },
    very_rounded: {
      buttonRadius: `100px`,
      cardRadius: `12px`,
    },
  }

  // TODO - keep in sync with defaults in questions.js
  const colorTheme = Themes[formData.color_primary] || Themes['blue']
  const fontTheme = fontThemes[formData.font_display] || fontThemes['simple']
  const roundednessTheme = roundednessThemes[formData.ui_roundedness] || roundednessThemes['normal_rounded']

  const theme = {
    ...colorTheme,
    ...fontTheme,
    ...roundednessTheme,
    boldColor: formData.color_boldness === 'color_bold',
    grayBg: formData.color_gray_bg === 'color_gray_y',
    navType: formData.navigation_type_picker === 'horizontal' ? 'horizontal' : 'vertical',
  }
  
  const blockerMessage = `Hi, I know this div isn't really preventing you from removing the watermark, but please buy this design anyway, I worked really hard on it. Thank you :'-)`

  return <PreviewWrapper ref={outerTarget}>
    <PreviewBadge>Preview</PreviewBadge>
    <Blocker data-message={blockerMessage}></Blocker>
    <Watermark>reflow.design</Watermark>
    <ThemeProvider theme={theme}>
      <PreviewInner ref={innerTarget} scaleFactor={scaleFactor} perspective={false}>
        { PreviewComponent && <PreviewComponent formData={formData} /> }
      </PreviewInner>
    </ThemeProvider>
  </PreviewWrapper>
}

const PreviewWrapper = styled.div`
  position: relative;
  overflow: hidden;  
  user-select: none !important;
  cursor: auto !important;
  background-color: var(--background-item);
  background-image: url(${dots});
  background-position: 20px 20px; /* offset so dots aren't in the corner */
`
const Blocker = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
`
const Watermark = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  z-index: 10;
  font-weight: bold;
  font-size: 10vh;
  opacity: .1;
`
const PreviewInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale( ${p => p.scaleFactor || 1} ) ${p => p.perspective && 'perspective(1500px) rotateX(19deg) rotateY(-12deg) rotateZ(6deg)'};
`
const PreviewBadge = styled.div`
  background: var(--background-item);
  padding: .25rem .5rem;

  position: absolute;
  top: .5rem;
  left: .5rem;
  
  color: var(--text-tertiary);
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  font-size: var(--xs);

  border-radius: 4px;
  z-index: 1;
`

export default Preview
