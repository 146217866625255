import getCollectionName from '../../helpers/getCollectionName'

import {
  Page,
  PageHeader,
  ContentBox,
} from '../../styles/Previews'

const PreviewFeatures = ({ formData }) => {
  const {
    collection_name_plural,
    profile_pic_yn,
    gallery_banner_pic,
    favorite_yn,
    favorite_label,
    item_for_sale_yn,
    item_ratings_reviews_yn,
    item_comments_yn,
  } = formData

  return <Page>
    { gallery_banner_pic === 'gallery_pics' ?
      <div>gallery</div>
      :
      null
    }

    <PageHeader>My {getCollectionName(collection_name_plural, { plural: false, capital: false})}</PageHeader>

    { profile_pic_yn === 'profile_pic_y' &&
      <div>profile pic</div>
    }
    { favorite_yn === 'favorite_y' &&
      <div>{favorite_label}</div>
    }
    
    <br />
    
    <ContentBox />
    <ContentBox />
    <ContentBox />

    { item_for_sale_yn === 'item_for_sale_y' &&
      <div>for sale!</div>
    }
    { item_ratings_reviews_yn === 'item_ratings_reviews_y' &&
      <div>ratings &amp; reviews</div>
    }
    { item_comments_yn === 'item_comments_y' &&
      <div>comments too</div>
    }

  </Page>
}

export default PreviewFeatures
