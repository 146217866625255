import styled from 'styled-components/macro'

const SortableListWrapper = styled.div`
  /* padding: .5rem .5rem 0.01rem; */
  /* background: ${p => p.isDraggingOver ? 'var(--background-item-dark)' : 'var(--background-item)'}; */
  /* transition: all .2s ease; */
  /* border-radius: 6px; */
  margin: 0 0 1rem;
  min-height: 40px;
`
const SortableListItem = styled.div`
  display: grid;
  grid-template-columns: max-content auto max-content;
  grid-gap: .5rem;
  align-items: center;
  
  padding: .5rem;
  background: white;
  box-shadow: ${p => p.isDragging ? '0 4px 8px var(--shadow-dark)' : 'none'};
  transition: box-shadow .2s ease;
  /* border-radius: 4px; */
  cursor: pointer;
  margin: 0 0 .5rem;
  user-select: none;
  border-bottom: 1px solid var(--hairline);
`
const SortableDragHandle = styled.div`
  display: flex;
  
  svg {
    opacity: .5;
    width: 18px !important;
    height: 18px !important;
  }
`
const SortableMenuIcon = styled.div`
  border-radius: 100px;
  width: 18px;
  height: 18px;

  &:hover {
    background: var(--hover);
    cursor: pointer;
  }
  &:active {
    background: var(--hover);
  }
  
  svg {
    opacity: .5;
    width: 18px !important;
    height: 18px !important;
  }
`
const Adder = styled.button`
  font-family: inherit;
  font-size: var(--s);
  color: inherit;
  border: none;
  appearance: none;
  padding: 0;
  opacity: .8;
  background: transparent;
  text-decoration: underline;
  cursor: pointer;
  margin: 0 0 0 1rem;

  &:hover {
    opacity: 1;
  }
`
const Group = styled.div`
  margin: 0 0 2rem;
`

export {
  SortableListWrapper,
  SortableListItem,
  SortableDragHandle,
  SortableMenuIcon,
  Adder,
  Group,
}
