import NavMain from './components/NavMain'
import {
  AppBody,
  AppNav,
  AppMain,
  ContentBox,
} from '../../styles/Previews'

const PreviewColor = ({ formData }) => {
  return <AppBody>
    <AppNav>
      <NavMain formData={formData} />
      <div />
    </AppNav>
    <AppMain>
      <ContentBox />
      <ContentBox />
      <ContentBox />
      <ContentBox />
      <ContentBox />
      <ContentBox />
      <ContentBox />
    </AppMain>
  </AppBody>
}

export default PreviewColor
