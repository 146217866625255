import {
  AppNavMain,
  NavLogo,
  NavItem,
  NavIcon,
  NavText,
  SearchInput,
} from '../../../styles/Previews'
import { NavItems } from '../data/navItems'

const NavMain = ({ formData }) => {
  const {
    collections,
    main_pages_items,
    app_name,
    navigation_type_picker,
    search_yn,
  } = formData
  
  const allItems = main_pages_items && main_pages_items.map(i => NavItems[i])
  const aboveItems = allItems && allItems.filter(i => i.emphasized)
  const belowItems = allItems && allItems.filter(i => !i.emphasized)

  const activeZone = (aboveItems && aboveItems.length > 0) ? 'above' :
                     (collections && collections.length > 0) ? 'collections' :
                     'below'

  return <AppNavMain>
    <NavLogo>{app_name || 'MyApp'}</NavLogo>
    {search_yn === 'search_y' && navigation_type_picker !== 'horizontal' && <SearchInput type="search" placeholder="Search..." />}

    {aboveItems && aboveItems.map( (item, index) => {
      const { icon, label } = item
      return <NavItem key={label} className={index === 0 && activeZone === 'above' && 'active'}>
        {navigation_type_picker !== 'horizontal' && <NavIcon>
          <span className="iconify" data-icon={`mdi-${icon || 'square'}`} />
        </NavIcon> }
        <NavText>{label || 'Page'}</NavText>
      </NavItem>
    })}

    {
      aboveItems &&
      aboveItems.length > 0 &&
      <hr />
    }

    {collections && collections.map( (item, index) => {
      return <NavItem key={item} className={index === 0 && activeZone === 'collections' && 'active'}>
        {navigation_type_picker !== 'horizontal' && <NavIcon>
          <span className="iconify" data-icon={`mdi-${item.icon || 'square'}`} />
        </NavIcon> }
        <NavText>{item.title || 'Page'}</NavText>
      </NavItem>
    })}

    {
      belowItems &&
      belowItems.length > 0 &&
      <hr />
    }

    {belowItems && belowItems.map( (item, index) => {
      const { icon, label } = item
      return <NavItem key={label} className={index === 0 && activeZone === 'below' && 'active'}>
        {navigation_type_picker !== 'horizontal' && <NavIcon>
          <span className="iconify" data-icon={`mdi-${icon || 'square'}`} />
        </NavIcon> }
        <NavText>{label || 'Page'}</NavText>
      </NavItem>
    })}

  </AppNavMain>
}

export default NavMain
