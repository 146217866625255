export const NavItems = {
  favorites: {
    label: `Favorites`,
    icon: `heart`,
    level: `primary_nav`,
    emphasized: true,
  },
  following: {
    label: `Following`,
    icon: `eye`,
    level: `primary_nav`,
    emphasized: true,
  },
  suggested: {
    label: `For you`,
    icon: `magic`,
    level: `primary_nav`,
    emphasized: true,
  },
  popular: {
    label: `Popular`,
    icon: `hot`,
    level: `primary_nav`,
    emphasized: true,
  },
  recent_changes: {
    label: `Recent`,
    icon: `pulse`,
    level: `primary_nav`,
    emphasized: true,
  },
  reports: {
    label: `Reports`,
    icon: `chart-box-outline`,
    level: `primary_nav`,
    emphasized: false,
  },
  calendar: {
    label: `Calendar`,
    icon: `calendar`,
    level: `primary_nav`,
    emphasized: false,
  },
  map: {
    label: `Map`,
    icon: `map`,
    level: `primary_nav`,
    emphasized: false,
  },
  kanban: {
    label: `Board`,
    icon: `trello`,
    level: `primary_nav`,
    emphasized: false,
  },
  settings: {
    label: `Settings`,
    icon: `cog`,
    level: `secondary_nav`,
    emphasized: true,
  },
  help: {
    label: `Help`,
    icon: `lifebuoy`,
    level: `secondary_nav`,
    emphasized: false,
  },
  upgrade: {
    label: `Upgrade`,
    icon: `star`,
    level: `secondary_nav`,
    emphasized: false,
  },
  whats_new: {
    label: `What's new`,
    icon: `shimmer`,
    level: `secondary_nav`,
    emphasized: false,
  },
  inbox: {
    label: `Messages`,
    icon: `inbox`,
    level: `secondary_nav`,
    emphasized: true,
  },
  feedback: {
    label: `Feedback`,
    icon: `feedback`,
    level: `secondary_nav`,
    emphasized: false,
  },
  notifications: {
    label: `Notifications`,
    icon: `bell`,
    level: `secondary_nav`,
    emphasized: true,
  },
  invite: {
    label: `Invite`,
    icon: `invite`,
    level: `secondary_nav`,
    emphasized: false,
  },
  logout: {
    label: `Log out`,
    icon: `logout`,
    level: `secondary_nav`,
    emphasized: false,
  },
  cart: {
    label: `Cart`,
    icon: `cart`,
    level: `secondary_nav`,
    emphasized: true,
  },
  checklist: {
    label: `Getting started`,
    icon: `progress-check`,
    level: `secondary_nav`,
    emphasized: true,
  },
}
