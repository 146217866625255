// import styled from 'styled-components/macro'

import { Select } from '../../styles/Forms'
import {
  QuestionWrapper,
  QuestionTitle,
  QuestionDescription,
  Example,
  Consequences,
  QuestionError,
} from '../../styles/Questions'

const QuestionSelect = ({
  question,
  formData,
  register,
  errors,
}) => {

  const { id, title, description, example, choices } = question

  const questionAnswer = formData[id]
  const chosenAnswer = choices && choices.find(c => c.id === questionAnswer)

  return <QuestionWrapper>
    <QuestionTitle>{ title(formData) }</QuestionTitle>
    {description && <QuestionDescription>{ description(formData) }</QuestionDescription> }
    {example && <Example>{ example }</Example> }
    <Select {...register(id)}>
      { choices && choices.map(choice => {
        return <option
          key={choice.id}
          value={choice.id}
          disabled={choice.disabled}
        >
          {choice.title}
          {choice.id === question.default(formData) && ' (Default)'}
          {choice.description && ` - ${choice.description}`}
        </option>
      })}
    </Select>
    {chosenAnswer && chosenAnswer.consequence && <Consequences>{ chosenAnswer.consequence }</Consequences> }
    {errors[id] && <QuestionError>This field is required</QuestionError>}
  </QuestionWrapper>
}

export default QuestionSelect
