import styled from 'styled-components/macro'

import {
  DialogRoot,
  DialogPortal,
  DialogOverlay,
  DialogContent,
  DialogTitle,
  DialogClose,
} from '../components/Dialog'

const Payment = ({
  formData,
  isPaying,
  setIsPaying,
}) => {

  const jsonString = JSON.stringify(formData)

  return <DialogRoot
    id={'paying'}
    open={isPaying}
    onOpenChange={() => setIsPaying(!isPaying)}
    modal={true}
  >
    <DialogPortal>
      <DialogOverlay />
      <DialogContent>
        <DialogTitle>Payment instructions</DialogTitle>
        <DialogClose>✕</DialogClose>
        <p>Thank you for purchasing a Reflow design! While we're in our alpha phase, we're handling all purchases manually over email.</p>
        <p>Please send an email to <strong>sam@directedworks.com</strong> to request a purchase. We'll send you a secure Stripe link to make a credit card payment.</p>
        <p style={{color: 'red'}}>IMPORTANT: Copy and paste the code below and include it in your email. We need this to create your custom design. DO NOT close this tab until you've saved that text, it cannot be recovered once you close the tab.</p>
        <p>Thank you for your patience as we get up and running {':-)'}</p>
        <MonoWrapper>{jsonString}</MonoWrapper>
      </DialogContent>
    </DialogPortal>
  </DialogRoot>

}

const MonoWrapper = styled.div`
  padding: 1rem;
  background: var(--background-item);
  font-family: monospace;
  font-size: 10px;
`

export default Payment
