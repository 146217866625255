import styled from 'styled-components/macro'

const PreviewWelcome = () => {
  return <DottedBox>
    Your app will show up here
  </DottedBox>
}

const DottedBox = styled.div`
  padding: 3rem;
  border-radius: 1rem;
  font-weight: 500;
  color: var(--text-tertiary);
  border: dashed 3px var(--input-border);
  text-align: center;
  background: var(--background-item);
`

export default PreviewWelcome
