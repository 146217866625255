import {
  QuestionWrapper,
  QuestionTitle,
  QuestionDescription,
  Example,
  Option,
  OptionTitle,
  OptionDescription,
  Consequences,
  QuestionError,
} from '../../styles/Questions'

const QuestionSingleChoice = ({
  question,
  formData,
  register,
  errors,
}) => {

  const { id, title, description, example, choices } = question

  const questionAnswer = formData[id]
  const chosenAnswer = choices && choices.find(c => c.id === questionAnswer)

  return <QuestionWrapper>
    <QuestionTitle>{ title(formData) }</QuestionTitle>
    {description && <QuestionDescription>{ description(formData) }</QuestionDescription> }
    {example && <Example>{ example }</Example> }
    <div>
      { choices && choices.map(choice => {
        return <Option key={choice.id} disabled={choice.disabled}>
          <input
            name={id}
            type="radio"
            value={choice.id}
            disabled={choice.disabled}
            {...register(id)}
          />
          <div>
            <OptionTitle>{choice.title}</OptionTitle>
            <OptionDescription>{choice.description}</OptionDescription>
            {choice.example && <Example>{ choice.example }</Example> }
          </div>
        </Option>
      })}
    </div>
    {chosenAnswer && chosenAnswer.consequence && <Consequences>{ chosenAnswer.consequence }</Consequences> }
    {errors[id] && <QuestionError>This field is required</QuestionError>}
  </QuestionWrapper>
}

export default QuestionSingleChoice
