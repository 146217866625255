import styled from 'styled-components/macro'
import { Controller } from 'react-hook-form'

import {
  QuestionWrapper,
  QuestionTitle,
  QuestionDescription,
  Example,
  QuestionError,
} from '../../styles/Questions'
import {
  CheckboxRoot,
  // CheckboxIndicator,
} from '../../components/Checkbox'

const QuestionPills = ({
  question,
  formData,
  control,
  errors,
}) => {

  const { id, title, description, example, choices } = question

  return <QuestionWrapper>
    <QuestionTitle>{ title(formData) }</QuestionTitle>
    {description && <QuestionDescription>{ description(formData) }</QuestionDescription> }
    {example && <Example>{ example }</Example> }
    <div>
      {choices && choices.map(choice => {
        const { id, title, description, example, disabled, icon, color } = choice
        return <Controller
          key={id}
          control={control}
          name={question.id}
          render={({ field: { onChange, value, name } }) => {
            return <PillWrapper
              onCheckedChange={newVal => {
                // console.log(`${name} - was`, value, `- now ${id} is ${newVal}`)
                value = value || []

                const result = choices.map(c => c.id)
                  .filter(cid => {
                    if (cid !== id) {            // for things that haven't been clicked
                      return value.includes(cid) // just check if it's in the array already
                    }
                    return newVal                 // otherwise check if it's on or off
                  })

                // console.log(`${name} will now be`, result)
                onChange(result)
              }}
              name={name}
              value={value}
              disabled={disabled}
              $color={color}
            >
              <span className="iconify" data-icon={`mdi-${icon || 'square'}`} />
              <div>
                <PillTitle>{title}</PillTitle>
                <PillDescription>{description}</PillDescription>
                {example && <Example>{ example }</Example> }
              </div>
            </PillWrapper>
          }}
        />
      })}
    </div>
    {errors[id] && <QuestionError>This field is required</QuestionError>}
  </QuestionWrapper>
}

const PillWrapper = styled(CheckboxRoot)`
  display: inline-grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 1rem;
  align-items: center;

  padding: .5rem 1rem;
  margin: 0 .75rem 1rem 0;

  background: white;
  border: 1px solid var(--hairline);
  border-radius: 300px;
  
  color: var(--text-secondary);
  font-family: inherit;
  font-size: var(--m);
  font-weight: 500;
  text-align: left;
  appearance: none;
  box-shadow: 0 2px 4px #eee;

  /* transition: all .2s ease; */
  user-select: none;
  cursor: pointer;

  &:hover {
    background: var(--hover);
    border: 1px solid var(--input-border);
  }
  
  &[data-state="checked"] {
    color: ${p => p.$color ? `var(--oc-${p.$color}-9)` : `var(--oc-blue-9`};
    background: ${p => p.$color ? `var(--oc-${p.$color}-0)` : `var(--oc-blue-0`};
    box-shadow: ${p => p.$color ? `0 0 0 2px var(--oc-${p.$color}-5)` : `0 0 0 2px var(--oc-blue-5`};
    border-color: transparent;

    &:hover {
      background: ${p => p.$color ? `var(--oc-${p.$color}-1)` : `var(--oc-blue-1`};
    }
  }

  svg {
    path {
      fill: ${p => p.$color ? `var(--oc-${p.$color}-6)` : `var(--oc-blue-6`};
    }
  }
  div {

  }
`
const PillTitle = styled.div`
`
const PillDescription = styled.div`
`

export default QuestionPills
