import styled from 'styled-components/macro'

import { Actions } from '../data/actions'

const ActionsMenu = ({ formData }) => {
  const {
    item_actions,
    editing_yn,
    archive_yn,
  } = formData

  return <PreviewActionsMenu>
    {item_actions && item_actions.map(action => {
      const { icon, label } = Actions[action]
      return <PreviewActionsItem key={action}>
        <PreviewActionsItemIcon>
          <span className="iconify" data-icon={`mdi-${icon || 'square'}`} />
        </PreviewActionsItemIcon>
        <span>{label}</span>
      </PreviewActionsItem>
    })}

    { archive_yn === 'archive_y' &&
      <PreviewActionsItem>
        <PreviewActionsItemIcon>
          <span className="iconify" data-icon={`mdi-archive`} />
        </PreviewActionsItemIcon>
        <span>Archive</span>
      </PreviewActionsItem>
    }

    { editing_yn === 'editing_y' &&
      <PreviewActionsItem destructive>
        <PreviewActionsItemIcon>
          <span className="iconify" data-icon={`mdi-trash`} />
        </PreviewActionsItemIcon>
        <span>Delete</span>
      </PreviewActionsItem>
    }

  </PreviewActionsMenu>
}

const PreviewActionsMenu = styled.div`
  width: 200px;
  border-radius: 4px;
  background: white;
  box-shadow: var(--shadow-elevation-medium);
  color: var(--text-primary);
  padding: .5rem;
`
const PreviewActionsItem = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  grid-gap: .75rem;
  padding: .75rem;
  border-bottom: 1px solid var(--hairline);
  align-items: center;
  color: ${p => p.destructive ? 'var(--destructive)' : 'var(--text-primary)'};
  font-weight: 500;
  font-size: var(--s);

  &:last-child {
    border-bottom: none;
  }
`
const PreviewActionsItemIcon = styled.div`
  opacity: .75;

  svg {
    display: block;
    width: 18px;
    height: 18px;
  }
`

export default ActionsMenu
