// import styled from 'styled-components/macro'

import {
  DialogPortal,
  DialogOverlay,
  DialogContent,
  DialogTitle,
  DialogClose,
} from '../../../components/Dialog'

const ListBuilderModal = ({
  answer,
  EditorForm,
}) => {  
  return <DialogPortal>
    <DialogOverlay />
    <DialogContent>
      <DialogTitle>{answer === '_custom' ? 'custom' : answer.id}</DialogTitle>
      <DialogClose>✕</DialogClose>
      <EditorForm />
    </DialogContent>
  </DialogPortal>
}

export default ListBuilderModal
