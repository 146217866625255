import { useState } from 'react'
import styled from 'styled-components/macro'

import chevronLeft from '../../svg/ui/chevron-left.svg'
import Pages from '../../data/pages'
import PanelNav from './PanelNav'
import useWindowSize from '../../hooks/useWindowSize'
import Form from '../Form'
import Errors from './Errors'
import Preview from '../Preview'
import { Link, useParams } from 'react-router-dom'
import { Button } from '../../styles/Buttons'

const Panel = ({
  manualErrors,
  formData,
  saveFormData,
  setIsPurchasing,
}) => {

  const { currentPageId } = useParams()

  const [ menuIsOpen, setMenuIsOpen ] = useState(false)

  const pages = Pages.filter(p => !p.inWizard)
  const currentPage = pages.find(p => p.id === currentPageId)
  
  const size = useWindowSize()
  const MOBILE_BREAKPOINT = 600
  const isMobile = size.width < MOBILE_BREAKPOINT

  return <PanelWrapper>

    <PanelHeader>
      <PanelHeaderLogo>
        {isMobile ?
          <>
            <div>
              <HamburgerIcon onClick={() => setMenuIsOpen(!menuIsOpen)}>
                <svg style={{ width:'24px', height:'24px' }} viewBox="0 0 24 24">
                  <path fill="currentColor" d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
                </svg>
              </HamburgerIcon>
              {
                menuIsOpen &&
                <Menu>
                  <PanelNav
                    pages={pages}
                    currentPage={currentPage}
                    closeOnClick={isMobile}
                    setMenuIsOpen={setMenuIsOpen}
                    manualErrors={manualErrors}
                    formData={formData}
                  />
                </Menu>
              }
            </div>
            <div>{currentPage.title}</div>
          </>
        :
          <NameWrapper>
            <BackArrow to="/">
              <img alt="back" src={chevronLeft} />
            </BackArrow>
            <div>{formData.app_name || 'Untitled'}</div>
          </NameWrapper>
        }
      </PanelHeaderLogo>
      <PanelHeaderRight>
        <Errors
          manualErrors={manualErrors}
        />

        <Button
          $small
          disabled={manualErrors.length > 0}
          onClick={event => {
            event.preventDefault()
            setIsPurchasing(true)
          }}
        >
          Download this design
        </Button>
      </PanelHeaderRight>
    </PanelHeader>
    
    <PanelBody>
      {!isMobile &&
        <PanelNavWrapper>
          <PanelNav
            pages={pages}
            formData={formData}
            currentPage={currentPage}
            manualErrors={manualErrors}
          />
        </PanelNavWrapper>
      }

      <PanelFormWrapper>
        <Form
          key={currentPageId} /* rerender each page fresh */
          currentPage={currentPage}
          currentPageId={currentPageId}
          saveFormData={saveFormData}
          formData={formData}
          manualErrors={manualErrors}
        />
      </PanelFormWrapper>

      <Preview
        formData={formData}
        currentPage={currentPage}
      />

    </PanelBody>
  </PanelWrapper>
}

const PanelWrapper = styled.div`
  display: grid;
  // https://css-tricks.com/preventing-a-grid-blowout/
  grid-template-rows: max-content minmax(0, 1fr);
  height: 100vh;
`
const PanelHeader = styled.div`
  display: grid;
  grid-template-columns: auto max-content max-content;
  grid-gap: 1rem;
  align-items: center;
  padding: .666rem 1rem;
  border-bottom: 1px solid var(--hairline);
`
const PanelHeaderLogo = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 1rem;
  align-items: center;
`
const PanelHeaderRight = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 1rem;
  align-items: center;
`
const PanelBody = styled.div`
  display: grid;
  grid-template-columns: 200px 3fr 3fr;

  @media(max-width: 600px) {
    grid-template-columns: auto;
    grid-template-rows: 200px auto; /* height of preview wrapper */
  }
`
const PanelNavWrapper = styled.div`
  padding: 1rem 0;
  border-right: 1px solid var(--hairline);
`
const PanelFormWrapper = styled.div`
  padding: 2rem;
  overflow-y: auto;
  /* max-width: 600px; */

  @media(max-width: 600px) {
    order: 1;
    overflow-y: initial;
  }
`
const Menu = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: white;
  z-index: 10;
  top: 0;
  left: 0;
  padding: 6rem .5rem 2rem;
  background: white;
  z-index: 1000;
`
const HamburgerIcon = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;
  z-index: 2000;
  position: relative;
  cursor: pointer;
`
const NameWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: max-content max-content;
`
const BackArrow = styled(Link)`
  opacity: .4;
  cursor: pointer;
  padding: .25rem .5rem .25rem 0;

  img {
    width: 20px;
    height: 20px;
    display: block;
  }
  &:hover {
    opacity: .6;
  }
`

export default Panel
