import PreviewActions from              '../views/previews/PreviewActions'
import PreviewAppName from              '../views/previews/PreviewAppName'
import PreviewAttributes from           '../views/previews/PreviewAttributes'
import PreviewAuth from                 '../views/previews/PreviewAuth'
// import PreviewCollectionName from       '../views/previews/PreviewCollectionName'
import PreviewColor from                '../views/previews/PreviewColor'
// import PreviewCreating from             '../views/previews/PreviewCreating'
import PreviewDashboard from            '../views/previews/PreviewDashboard'
// import PreviewDeleting from             '../views/previews/PreviewDeleting'
import PreviewEditing from              '../views/previews/PreviewEditing'
import PreviewEmail from                '../views/previews/PreviewEmail'
import PreviewFeatures from             '../views/previews/PreviewFeatures'
import PreviewFeel from                 '../views/previews/PreviewFeel'
// import PreviewFonts from                '../views/previews/PreviewFonts'
import PreviewLegal from                '../views/previews/PreviewLegal'
import PreviewLists from                '../views/previews/PreviewLists'
import PreviewLocalization from         '../views/previews/PreviewLocalization'
import PreviewNavMain from              '../views/previews/PreviewNavMain'
import PreviewNavOther from             '../views/previews/PreviewNavOther'
import PreviewNavType from              '../views/previews/PreviewNavType'
import PreviewNotifications from        '../views/previews/PreviewNotifications'
import PreviewOnboarding from           '../views/previews/PreviewOnboarding'
import PreviewPricing from              '../views/previews/PreviewPricing'
import PreviewProfile from              '../views/previews/PreviewProfile'
import PreviewSearch from               '../views/previews/PreviewSearch'
// import PreviewSummary from              '../views/previews/PreviewSummary'
import PreviewTeams from                '../views/previews/PreviewTeams'
// import PreviewUsers from                '../views/previews/PreviewUsers'
import PreviewWelcome from              '../views/previews/PreviewWelcome'

const Pages = [
  {
    id: `wizard_welcome`,
    title: `Wizard Welcome`,
    PreviewComponent: PreviewWelcome,
    inWizard: true,
  },
  {
    id: `wizard_type`,
    title: `Wizard Type`,
    PreviewComponent: PreviewAppName,
    inWizard: true,
  },
  {
    id: `wizard_data`,
    title: `Wizard Data`,
    PreviewComponent: PreviewAppName,
    inWizard: true,
  },
  // {
  //   id: `wizard_users`,
  //   title: `Wizard Users`,
  //   PreviewComponent: PreviewUsers,
  //   inWizard: true,
  // },
  {
    id: `wizard_finish`,
    title: `Wizard Finish`,
    PreviewComponent: PreviewDashboard,
    inWizard: true,
  },
  {
    id: `accounts`,
    title: `Accounts`,
    icon: `key`,
    parent: `users`,
    PreviewComponent: PreviewAuth,
  },
  {
    id: `profile`,
    title: `Profiles`,
    icon: `user`,
    parent: `users`,
    PreviewComponent: PreviewProfile,
  },
  {
    id: `teams`,
    title: `Teams`,
    icon: `people`,
    parent: `users`,
    PreviewComponent: PreviewTeams,
  },
  {
    id: `data_name`,
    title: `Basics`,
    icon: `playlist-check`,
    parent: `data`,
    PreviewComponent: PreviewAttributes,
    dataPage: true,
  },
  {
    id: `data_features`,
    title: `Features`,
    icon: `magic`,
    parent: `data`,
    PreviewComponent: PreviewFeatures,
    dataPage: true,
  },
  {
    id: `actions`,
    title: `Actions`,
    icon: `run`,
    parent: `data`,
    PreviewComponent: PreviewActions,
    dataPage: true,
  },
  {
    id: `lists`,
    title: `Lists`,
    icon: `view-list`,
    parent: `data`,
    PreviewComponent: PreviewLists,
    dataPage: true,
  },
  {
    id: `editing`,
    title: `Editing`,
    icon: `edit`,
    parent: `data`,
    PreviewComponent: PreviewEditing,
    dataPage: true,
  },
  {
    id: `onboarding`,
    title: `Onboarding`,
    icon: `flag-outline`,
    parent: `addons`,
    PreviewComponent: PreviewOnboarding,
  },
  {
    id: `dashboard`,
    title: `Dashboard`,
    icon: `gauge`,
    parent: `addons`,
    PreviewComponent: PreviewDashboard,
  },
  {
    id: `search`,
    title: `Search`,
    icon: `search`,
    parent: `addons`,
    PreviewComponent: PreviewSearch,
  },
  {
    id: `pricing`,
    title: `Pricing`,
    icon: `money`,
    parent: `addons`,
    PreviewComponent: PreviewPricing,
  },
  {
    id: `notifications`,
    title: `Notifications`,
    icon: `bell-outline`,
    parent: `addons`,
    PreviewComponent: PreviewNotifications,
  },
  {
    id: `email`,
    title: `Email`,
    icon: `email-outline`,
    parent: `addons`,
    PreviewComponent: PreviewEmail,
  },
  {
    id: `localization`,
    title: `Localization`,
    icon: `globe`,
    parent: `addons`,
    PreviewComponent: PreviewLocalization,
  },
  {
    id: `legal`,
    title: `Legal`,
    icon: `script-outline`,
    parent: `addons`,
    PreviewComponent: PreviewLegal,
  },
  {
    id: `main_pages`,
    title: `Main pages`,
    icon: `cog`,
    parent: `navigation`,
    PreviewComponent: PreviewNavMain,
  },
  {
    id: `other_pages`,
    title: `Other pages`,
    icon: `cog`,
    parent: `navigation`,
    PreviewComponent: PreviewNavOther,
  },
  {
    id: `navigation_type`,
    title: `Type`,
    icon: `cog`,
    parent: `navigation`,
    PreviewComponent: PreviewNavType,
  },
  {
    id: `colors`,
    title: `Colors`,
    icon: `paint`,
    parent: `style`,
    PreviewComponent: PreviewColor,
  },
  {
    id: `feel`,
    title: `Feel`,
    icon: `image`,
    parent: `style`,
    PreviewComponent: PreviewFeel,
  },
]

export default Pages
