import styled from 'styled-components/macro'
import getCollectionName from '../../helpers/getCollectionName'

import {
  Page,
  PageHeader,
  ContentBox,
} from '../../styles/Previews'

const PreviewLists = ({ formData }) => {
  const {
    collection_name_plural,
    list_size,
    list_grouped_yn,
  } = formData

  const big = list_size === '5'

  return <Page>
    <PageHeader>{getCollectionName(collection_name_plural, { plural: true, capital: true})}</PageHeader>
    {list_grouped_yn === 'list_grouped_y' &&
      <Tabs>
        <Tab selected>Foo</Tab>
        <Tab>Bar</Tab>
        <Tab>Baz</Tab>
      </Tabs>
    }

    <ContentBox $small={!big} />
    <ContentBox $small={!big} />
    <ContentBox $small={!big} />
    { list_size !== '5' && <>
      <ContentBox $small={!big} />
      <ContentBox $small={!big} />
      <ContentBox $small={!big} />
      <ContentBox $small={!big} />
      <ContentBox $small={!big} />
      <ContentBox $small={!big} />
    </> }
    
    { (list_size === '500' || list_size === 'more') &&
      <Pagination>« 1 2 3 4 5 »</Pagination>
    }
  </Page>
}

const Tabs = styled.div`
  margin: 0 0 1rem;
  border-bottom: 1px solid #ccc;
`
const Tab = styled.div`
  margin: 0 0 -1px;
  padding: .5rem;
  display: inline-block;
  border-bottom: 3px solid;
  border-bottom-color: ${p => p.selected ? 'black' : 'transparent'};
`
const Pagination = styled.div`
  margin: 1rem 0;
  text-align: center;
`

export default PreviewLists
