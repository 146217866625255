// import styled from 'styled-components/macro'

import { Select } from '../../../styles/Forms'
import AttributeTypes from '../../../data/attribute_types'

const Attribute = ({
  answer,
}) => {  
  return <form>
    <label>
      Attribute name
      <input type="text" />
    </label>
    <label>
      Description
      <input type="text" />
    </label>
    <label>
      What type of data is this?
      <Select>
        { AttributeTypes.map(at => {
          return <option key={at.id} value={at.id}>{at.title}</option>
        })}
      </Select>
    </label>
    <label>
      Required
      <Select>
        <option value="yes">Yes</option>
        <option value="no">No</option>
      </Select>
    </label>
    <label>
      Allow multiple?
      <Select>
        <option value="yes">Yes</option>
        <option value="no">No</option>
      </Select>
    </label>
    <button>Save changes</button>
    <button>Cancel</button>
  </form>
}

export default Attribute
