import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import Questions from '../data/questions'
import QuestionUnknown from './questions/QuestionUnknown'

const Form = ({
  currentPageId,
  saveFormData,
  formData,
  manualErrors,
  onFormSubmit,
  FormNav,
}) => {

  const questions = Questions.filter(q => q.page === currentPageId)

  // generate schema for this form
  const yupValidations = questions.reduce((result, question) => {
    if (!question.yup_validation) {
      return result
    }
    return {
      ...result,
      [question.id]: question.yup_validation,
    }
  }, {})
  const schema = yup.object(yupValidations)
  
  // generate default values for this form
  // TODO - don't duplicate here and in Wizard.js
  const defaultValues = questions.reduce((result, question) => {
    if (!question.default && !formData[question.id]) {
      return result
    }
    return {
      ...result,
      [question.id]: formData[question.id] || question.default(formData),
    }
  }, {})

  // set up form with react-hook-form
  const {
    register,
    watch,
    handleSubmit,
    formState,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  })

  // when the form first loads,
  // save the default values to formData
  // so previews are already
  useEffect(() => {
    saveFormData(defaultValues)
  }, []) // eslint-disable-line
  
  // watch for form updates
  useEffect(() => {
    const subscription = watch((value) => saveFormData(value))
    return () => subscription.unsubscribe()
  }, [ watch, saveFormData ])

  return <form onSubmit={onFormSubmit && handleSubmit(onFormSubmit)}>
    {questions.map(question => {
      if (question.displayTest && !question.displayTest(formData)) {
        return false
      }
      const QuestionComponent = question.QuestionComponent || QuestionUnknown
      return <QuestionComponent
        key={question.id}
        question={question}
        formData={formData}
        questions={Questions}
        manualErrors={manualErrors}
        errors={errors}
        register={register}
        saveFormData={saveFormData}
        control={control}
      />
    })}

    {FormNav &&
      <FormNav formState={formState} />
    }
  </form>
}

export default Form
