import styled from 'styled-components/macro'

import {
  NavItem,
  NavIcon,
  NavText,
  NavAccountPopup,
  NavAccountPopupPerson,
  PopupAvatar,
} from '../../../styles/Previews'
import { NavItems } from '../data/navItems'

const NavMenu = ({ formData, solo }) => {
  const {
    teams_yn,
    nav_other_pages,
  } = formData

  const allItems = nav_other_pages.map(i => NavItems[i])
  const items = allItems.filter(i => !i.emphasized)

  return <NavAccountPopup solo={solo}>

    <NavAccountPopupPerson>
      <PopupAvatar alt="profile pic" src="https://www.placebear.com/100/100" size={60} />
      <strong>Jamie Doe</strong>
    </NavAccountPopupPerson>

    {teams_yn === 'teams_y' &&
      <TeamWrapper>
        <NavItem>
          <NavIcon>
            <span className="iconify" data-icon={`mdi-domain`} />
          </NavIcon>
          <NavText>Acme, Inc</NavText>
        </NavItem>
        <NavItem>
          <NavIcon>
            <span className="iconify" data-icon={`mdi-domain`} />
          </NavIcon>
          <NavText>Worldwide LLC</NavText>
        </NavItem>
      </TeamWrapper>
    }

    {items && items.map( item => {
      const { icon, label } = item
      return <NavItem key={label}>
        <NavIcon>
          <span className="iconify" data-icon={`mdi-${icon || 'square'}`} />
        </NavIcon>
        <NavText>{label || 'Page'}</NavText>
      </NavItem>
    })}

  </NavAccountPopup>
}

const TeamWrapper = styled.div`
  padding: .5rem 0;
  border-bottom: 1px solid var(--hairline);
`

export default NavMenu
