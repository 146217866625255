import styled from 'styled-components/macro'

import { Actions } from './data/actions'

import getCollectionName from '../../helpers/getCollectionName'
import ActionsMenu from './components/ActionsMenu'
import {
  Page,
  PageHeader,
  Button,
  ContentBox,
} from '../../styles/Previews'

const PreviewActions = ({ formData }) => {
  const {
    collection_name_plural,
    item_actions,
  } = formData
  const primaryActions = item_actions && item_actions.slice(0, 2)
  const secondaryActions = item_actions && item_actions.slice(3)

  const showSecondaryActions = secondaryActions && secondaryActions.length > 0

  return <Page>

    <PageHeader>My {getCollectionName(collection_name_plural, { plural: false, capital: false})}</PageHeader>
    {/* {mainAction && <button>{mainAction.title}</button>} */}

    <ButtonRow>
      {primaryActions && primaryActions.map( (item, index) => {
        const { label } = Actions[item]
        return <Button key={item} $secondary={index !== 0}>
          {label}
        </Button>
      })}
      {showSecondaryActions &&
        <ActionMenuIcon>
          <svg style={{width:'24px',height:'24px'}} viewBox="0 0 24 24">
            <path fill="currentColor" d="M16,12A2,2 0 0,1 18,10A2,2 0 0,1 20,12A2,2 0 0,1 18,14A2,2 0 0,1 16,12M10,12A2,2 0 0,1 12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12M4,12A2,2 0 0,1 6,10A2,2 0 0,1 8,12A2,2 0 0,1 6,14A2,2 0 0,1 4,12Z" />
          </svg>
        </ActionMenuIcon>
      }
    </ButtonRow>

    { showSecondaryActions &&
      <MenuPositionHelper>
        <ActionsMenu formData={formData} />
      </MenuPositionHelper>
    }

    <hr />
    
    <ContentBox />
    <ContentBox />
    <ContentBox />
    <ContentBox />
    <ContentBox />
    <ContentBox />

  </Page>
}

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
`
const MenuPositionHelper = styled.div`
  position: absolute;
  top: 130px;
  right: 40px;
`
const ActionMenuIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  
  border-radius: 100px;
  width: 24px;
  height: 24px;
  background: #eee;

  svg {
    opacity: .5;
    width: 18px !important;
    height: 18px !important;
  }
`

export default PreviewActions
