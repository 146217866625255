import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'

import PageGroups from '../../data/pageGroups'
import groupByArray from '../../helpers/groupBy'
import {
  AccordionRoot,
  AccordionItem,
  AccordionHeader,
  AccordionTrigger,
  AccordionContent,
} from '../../components/Accordion'

const PanelNav = ({
  pages,
  currentPage,
  closeOnClick,
  setMenuIsOpen,
  formData,
  manualErrors,
}) => {
  const dataPages = pages.filter(p => p.dataPage)
  const otherPages = pages.filter(p => !p.dataPage)
  const otherPagesGrouped = groupByArray(otherPages, 'parent')

  // TODO - default this to open if we open on this route
  // const navItemSiblings = pages.filter(s => s.parent === page.parent)
  // const navItemSiblingsIds = navItemSiblings.map(s => s.id)
  // if (navItemSiblingsIds.includes(currentPage.id)) {
  //   const visible = true
  // }

  const {
    collections,
  } = formData

  return <PanelNavWrapper>
    <AccordionRoot type="single">
      <div>

        {collections && collections.map(collection => {
          return <AccordionItem key={collection.id} value={collection.id}>
            <AccordionHeader asChild={true}>
              <div>
                <AccordionTrigger asChild={true}>
                  <MainNavItem>
                    <span className="iconify" data-icon={`mdi-format-list-bulleted`} />
                    <div>{collection.title}</div>
                  </MainNavItem>
                </AccordionTrigger>
              </div>
            </AccordionHeader>
            <AccordionContent asChild={true}>
              <SecondaryWrapper>
                {dataPages.map(page => {
                  return <SubNavItem
                    key={page.id}
                    to={`/build/${page.id}`} // pages.find(p => p.id === page.id).id
                    onClick={() => {
                      if (closeOnClick) {
                        setMenuIsOpen(false)
                      }
                    }}
                  >
                    <div>{page.title}</div>
                  </SubNavItem>
                })}
              </SecondaryWrapper>
            </AccordionContent>
          </AccordionItem>
        })}

        <PanelNavItemLink onClick={() => alert('coming soon!')}>+ Add data</PanelNavItemLink>

        <PanelNavHr />

        {otherPagesGrouped.map(pageGroup => {
          const { title, icon } = PageGroups[pageGroup.key]
          return <AccordionItem key={pageGroup.key} value={pageGroup.key}>
            <AccordionHeader asChild={true}>
              <div>
                <AccordionTrigger asChild={true}>
                  <MainNavItem>
                    <span className="iconify" data-icon={`mdi-${icon}`} />
                    <div>{title}</div>
                  </MainNavItem>
                </AccordionTrigger>
              </div>
            </AccordionHeader>
            <AccordionContent asChild={true}>
              <SecondaryWrapper>
                {pageGroup.values.map(page => {
                  const { id, title } = page
                  return <SubNavItem
                    key={id}
                    to={`/build/${id}`} // pages.find(p => p.id === page.id).id
                    onClick={() => {
                      if (closeOnClick) {
                        setMenuIsOpen(false)
                      }
                    }}
                  >
                    <div>{title}</div>
                  </SubNavItem>
                })}
              </SecondaryWrapper>
            </AccordionContent>
          </AccordionItem>
        })}

      </div>
    </AccordionRoot>

    <div>
      <MainNavItem as="a" target="_blank" href={`mailto:sam@directedworks.com`}>
        <span className="iconify" data-icon={`mdi-lifebuoy`} />
        <div>Help &amp; support</div>
      </MainNavItem>
    </div>

  </PanelNavWrapper>
}

const PanelNavWrapper = styled.div`
  display: grid;
  grid-template-rows: auto max-content;
  align-content: space-between;
  height: 100%;
`

// top level items
const MainNavItem = styled.button`
  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: .5rem;
  align-items: center;

  padding: .5rem 1rem .5rem 1rem;
  width: 100%;

  appearance: none;
  background: transparent;
  border: none;
  color: var(--text-primary);

  text-decoration: none;
  font-family: inherit;
  font-size: var(--m);
  font-weight: 500;
  text-align: left;
  cursor: pointer;

  &:hover {
    background: #f5f5f5;
  }
`

// sub nav items
const SecondaryWrapper = styled.div`
  /* background: #eee; */
`
const SubNavItem = styled(NavLink)`
  /* display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: .5rem;
  align-items: center; */

  display: block;
  width: 100%;
  padding: .5rem 1rem .5rem 2.5rem;

  color: var(--text-primary);
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background: var(--hover);
  }
  &.active {
    background: var(--background-item);
  }
`

// links

const PanelNavItemLink = styled.a`
  cursor: pointer;
  padding: .25rem 0 .25rem 2rem;
  text-decoration: none;
  color: var(--text-primary);
  font-size: var(--s);
  opacity: .7;
  
  &:hover {
    opacity: 1;
    text-decoration: underline;
  }
`
const PanelNavHr = styled.hr`
  margin: 1rem;
`

export default PanelNav
