import styled from 'styled-components/macro'

import getCollectionName from '../../helpers/getCollectionName'
import {
  Page,
  PageHeader,
} from '../../styles/Previews'

const PreviewAttributes = ({ formData }) => {
  const {
    collection_name_plural,
    item_attributes,
  } = formData

  const mostImportant = item_attributes && item_attributes.find(group => group.id === 'most_important')
  const lessImportant = item_attributes && item_attributes.find(group => group.id === 'less_important')

  return <Page>
    <PageHeader>My {getCollectionName(collection_name_plural, { plural: false, capital: false})}</PageHeader>
    <PreviewAttributesColumns>
      <div>
        {mostImportant && mostImportant.items && mostImportant.items.map(attribute => {
          return <PreviewAttributesMainItem key={attribute.id}>
            <span>{attribute.title}</span>
          </PreviewAttributesMainItem>
        })}
      </div>
      <div>
        {lessImportant && lessImportant.items && lessImportant.items.map(attribute => {
          return <PreviewAttributesSidebarItem key={attribute.id}>
            <span>{attribute.title}</span>
          </PreviewAttributesSidebarItem>
        })}
      </div>
    </PreviewAttributesColumns>
  </Page>
}

const PreviewAttributesColumns = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 1rem;
`
const PreviewAttributesMainItem = styled.div`
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 10px;
  font-size: var(--l);
  font-weight: 500;
  min-height: 120px;
  margin: 0 0 1rem;
`
const PreviewAttributesSidebarItem = styled.div`
  padding: .5rem 0;
  font-size: var(--s);
`

export default PreviewAttributes
