import styled from 'styled-components/macro'

import {
  Button,
} from '../../styles/Previews'

const PreviewFeel = ({ formData }) => {
  return <PreviewFeelWrapper>
    <FeelHeader>My app</FeelHeader>
    <Button>Test button</Button>
  </PreviewFeelWrapper>
}

const PreviewFeelWrapper = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 4px;
  box-shadow: var(--shadow-elevation-medium);
  width: 300px;
  border-radius: ${p => p.theme.cardRadius || '4px'};
`
const FeelHeader = styled.div`
  font-size: var(--l);
  font-weight: bold;
  margin: 0 0 1.5rem;
`

export default PreviewFeel
