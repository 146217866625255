import { useState } from 'react'
import styled from 'styled-components/macro'
import { nanoid } from 'nanoid/non-secure'

import {
  DialogRoot,
  DialogPortal,
  DialogOverlay,
  DialogContent,
} from '../components/Dialog'
import { Button } from '../styles/Buttons'
import Form from './Form'
import Pages from '../data/pages'
// import Preview from './Preview'
import Questions from '../data/questions'
import {Collections} from '../views/previews/data/collections'

const Wizard = ({
  formData,
  saveFormData,
}) => {

  // get wizard pages
  const pages = Pages.filter(p => p.inWizard)

  const [ currentPageIndex, setCurrentPageIndex ] = useState(0)

  const currentPage = pages[currentPageIndex]
  const currentPageId = currentPage.id

  // wizard nav
  const isOnLastPage = currentPageIndex === pages.length - 1
  const isOnFirstPage = currentPageIndex === 0

  const goNext = () => {
    if (isOnLastPage) {

      // TODO - don't duplicate here and in Form.js
      // generate all default values
      const defaultValues = Questions.reduce((result, question) => {
        if (!question.default && !formData[question.id]) {
          return result
        }
        return {
          ...result,
          [question.id]: formData[question.id] || question.default(formData),
        }
      }, {})


      // generate a list of default collections
      // we don't just throw the answer to the checkbox into "collections" because:
      //   1. we don't want to be adding/removing complete collections if they ever find their way back to that question
      //   2. we don't have to persist entire objects in the checkbox form (not really how react-hook-form works i don't think)
      const collections = formData['data_collection_defaults']
        .map(dc => Collections[dc])
        .map(c => ({
          ...c,
          id: nanoid(5),
        }))

      // save initial form data for all non-wizard fields
      saveFormData({
        ...defaultValues,
        collections,
        _hasFinishedWizard: true,
      })
    } else {
      setCurrentPageIndex(currentPageIndex + 1)
    }
  }
  const goPrev = () => {
    if (isOnFirstPage) {
      throw new Error(`Can't advance past the first page`)
    } else {
      setCurrentPageIndex(currentPageIndex - 1)
    }
  }

  const FormNav = ({ formState }) => <WizardNav>
    <div>
      {!isOnFirstPage &&
        <Button tertiary onClick={goPrev}>Back</Button>
      }
    </div>
    <div>
      {Object.keys(formState.errors).map( errorId => {
        return <ErrorText key={errorId}>Please use a valid {errorId}</ErrorText>
      })}
      <Button type="submit" disabled={!formState.isValid}>{ isOnLastPage ? 'Customize your design →' : 'Next'}</Button>
    </div>
  </WizardNav>

  return <DialogRoot
    id="wizard"
    open={!formData._hasFinishedWizard}
    modal={true}
  >
    <DialogPortal>
      <DialogOverlay />
      <DialogContent>

        {/* <WizardLayout> */}

          <WizardFormWrapper>
            <Form
              currentPageId={currentPageId}
              saveFormData={saveFormData}
              formData={formData}
              // manualErrors={manualErrors}
              onFormSubmit={goNext}
              FormNav={FormNav}
            />
          </WizardFormWrapper>

          {/* <Preview
            formData={formData}
            currentPage={currentPage}
          /> */}

        {/* </WizardLayout> */}

      </DialogContent>
    </DialogPortal>
  </DialogRoot>
}

// const WizardLayout = styled.div`
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   flex: 1; // weird way to make it fill the modal height?

//   @media(max-width: 600px) {
//     grid-template-columns: auto;
//     grid-template-rows: auto 200px; /* height of preview wrapper */
//   }
// `

const fadeBarHeight = '3rem'

const WizardFormWrapper = styled.div`
  // make fade bar not overlap shit
  padding: 0rem 0rem ${fadeBarHeight};
  position: relative;
`

const WizardNav = styled.div`
  position: absolute;
  bottom: 0;
  left: 0rem;
  right: 0rem;

  display: grid;
  grid-template-columns: max-content auto;
  justify-content: space-between;

  /* width: 100%; */
  padding: 1vh 0;
  border-top: 1px solid #eee;
  background: white;

  /* &:after {
    content: '';
    width: 100%;
    height: ${fadeBarHeight};
    background: linear-gradient(0deg, hsl(0 0% 100% / 1), hsl(0 0% 100% / 0));
    position: absolute;
    top: calc(-${fadeBarHeight} - 1px);
    left: 0;
    pointer-events: none;
  } */
`
const ErrorText = styled.div`
  color: red;
  margin: 0 0 1rem;
`

export default Wizard
