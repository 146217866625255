import * as Accordion from '@radix-ui/react-accordion';
import styled from 'styled-components/macro'

export const AccordionRoot = styled(Accordion.Root)`
`
export const AccordionItem = styled(Accordion.Item)`
`
export const AccordionHeader = styled(Accordion.Header)`
`
export const AccordionTrigger = styled(Accordion.Trigger)`
`
export const AccordionContent = styled(Accordion.Content)`
`
