const AttributeTypes = [
  {
    id: `attr_type_collection`,
    title: `Collection`,
    description: `Link another data type to this data`,
  },
  {
    id: `attr_type_user`,
    title: `User`,
    description: `Link to another user of the app`,
  },
  {
    id: `attr_type_toggle`,
    title: `Toggle`,
    description: `For data that is either on or off; true or false`,
  },
  {
    id: `attr_type_choice`,
    title: `Choice`,
    description: `Choosing data from a list`,
  },
  {
    id: `attr_type_date`,
    title: `Date`,
    description: `Good for setting a date`,
  },
  {
    id: `attr_type_short_text`,
    title: `Short text`,
    description: `Good for names`,
  },
  {
    id: `attr_type_long_text`,
    title: `Long text`,
    description: `Good for long descriptions or article content`,
  },
  {
    id: `attr_type_rich_text`,
    title: `Rich text`,
    description: `Similar to long text, but lets users format text, add images etc`,
  },
  {
    id: `attr_type_location`,
    title: `Location`,
    description: `An address, area, or point on the map`,
  },
  {
    id: `attr_type_images`,
    title: `Images`,
    description: `Pictures you can upload`,
  },
  {
    id: `attr_type_file`,
    title: `File`,
    description: `A file they upload`,
  },
  {
    id: `attr_type_code`,
    title: `Code`,
    description: `Users will be able to add custom computer code. Useful for letting end users customize blog posts etc.`,
  },
]

export default AttributeTypes
