export const Collections = {
  people: {
    title: `People`,
    icon: `account-multiple`,
  },
  places: {
    title: `Places`,
    icon: `map-marker`,
  },
  documents: {
    title: `Documents`,
    icon: `file-document-outline`,
  },
  tasks: {
    title: `Tasks`,
    icon: `checkbox-marked-circle-outline`,
  },
  products: {
    title: `Products`,
    icon: `dollar`,
  },
  media: {
    title: `Media`,
    icon: `play-box-multiple-outline`,
  },
  things: {
    title: `Items`,
    icon: `shape`,
  },
}
