import {
  OnboardingPage,
  OnboardingHeading,
  PageFlowWrapper,
  PageFlowArrow,
  PageFlowPoint,
} from '../../styles/Previews'

import ArrowDown from '../../svg/previews/arrow_down.svg'

const PreviewOnboarding = ({ formData }) => {
  const {
    app_name,
    onboarding_welcome_yn,
    onboarding_purpose_yn,
    onboarding_role_yn,
    onboarding_company_size_yn,
    onboarding_checklist_yn,
  } = formData
  
  return <PageFlowWrapper>
    <PageFlowPoint>Start</PageFlowPoint>
    <PageFlowArrow src={ArrowDown} />
    
    { onboarding_purpose_yn === 'onboarding_purpose_y' &&
      <>
        <OnboardingPage>
          <OnboardingHeading>What's your goal?</OnboardingHeading>
        </OnboardingPage>
        <PageFlowArrow src={ArrowDown} />
      </>
    }

    { onboarding_role_yn === 'onboarding_role_y' &&
      <>
        <OnboardingPage>
          <OnboardingHeading>What's your role?</OnboardingHeading>
        </OnboardingPage>
        <PageFlowArrow src={ArrowDown} />
      </>
    }

    { onboarding_company_size_yn === 'onboarding_company_size_y' &&
      <>
        <OnboardingPage>
          <OnboardingHeading>How big is your company?</OnboardingHeading>
        </OnboardingPage>
        <PageFlowArrow src={ArrowDown} />
      </>
    }

    { onboarding_welcome_yn === 'onboarding_welcome_y' &&
      <>
        <OnboardingPage>
          <OnboardingHeading>Welcome to {app_name || 'MyApp'}!</OnboardingHeading>
        </OnboardingPage>
        <PageFlowArrow src={ArrowDown} />
      </>
    }

    { onboarding_checklist_yn === 'onboarding_checklist_y' &&
      <>
        <OnboardingPage>
          <OnboardingHeading>Checklist 30% complete</OnboardingHeading>
        </OnboardingPage>
        <PageFlowArrow src={ArrowDown} />
      </>
    }

    <PageFlowPoint>Done</PageFlowPoint>

  </PageFlowWrapper>
}

export default PreviewOnboarding
