import * as yup from 'yup'

import QuestionBigText from             '../views/questions/QuestionBigText'
import QuestionBuilder from             '../views/questions/QuestionBuilder'
import QuestionIntro from               '../views/questions/QuestionIntro'
import QuestionCheckboxes from          '../views/questions/QuestionCheckboxes'
import QuestionPills from               '../views/questions/QuestionPills'
import QuestionSelect from              '../views/questions/QuestionSelect'
import QuestionSingleChoice from        '../views/questions/QuestionSingleChoice'
import QuestionToggle from              '../views/questions/QuestionToggle'

import AttributeForm from '../views/questions/builder_forms/AttributeForm'
// import PageForm from '../views/questions/builder_forms/PageForm'

import getCollectionName from '../helpers/getCollectionName'

const Questions = [
  {
    page: `wizard_welcome`,
    id: `welcome_intro`,
    name: `Welcome intro`,
    title: () => `Ahhh... that new app smell ☺️`,
    description: () => <>
      <p>
        You're just minutes away from generating a complete UX design for your app or website.
      </p>
      <p>
        Reflow is made for non-designers, so all the questions will be easy.
      </p>
      {/* <p>
        Let's start with the basics...
      </p> */}
      {/* <p>
        And if you get stuck, we have a lot of resources to help you out:
      </p>
      <ul>
        <li>
          <a href="https://reflow.design/support" target="_blank" rel="noreferrer">Documentation &amp; Support</a>
        </li>
        <li>
          <a href="https://reflow.design/tutorials" target="_blank" rel="noreferrer">Video series</a>
        </li>
        <li>
          <a href="https://reflow.design/contact" target="_blank" rel="noreferrer">Contact support team</a>
        </li>
      </ul>
      <p>
        When you're ready, hit the "Next" button below 👇
      </p> */}
    </>,
    QuestionComponent: QuestionIntro,
    // youtubeId: `wbIXmB2ZLmA`,
  },
  {
    page: `wizard_welcome`,
    id: `app_name`,
    name: `App name`,
    title: () => `What's the name of your app?`,
    description: () => `(You can change any of your answers later)`,
    example: `Example: TikTok, Quickbooks`,
    QuestionComponent: QuestionBigText,
    yup_validation: yup.string().required(),
    default: () => `MyApp`, // TODO - use signup email address to pre-fill here
  },
  {
    page: `wizard_type`,
    id: `welcome_followup`,
    name: `Welcome followup`,
    title: () => `Easy, right?`,
    description: () => <>
      <p>
        Every time you answer a question, you can see how it affects your design in the preview 👉
      </p>
    </>,
    QuestionComponent: QuestionIntro,
    // youtubeId: `wbIXmB2ZLmA`,
  },
  // {
  //   page: `wizard_type`,
  //   id: `app_platform`,
  //   name: `Platform`,
  //   title: () => `What kind of app is it?`,
  //   QuestionComponent: QuestionSingleChoice,
  //   yup_validation: yup.string().required(),
  //   default: () => `web`,
  //   choices: [
  //     {
  //       id: `web`,
  //       title: `Website`,
  //       description: `Includes desktop, laptop, and tablet sizes`,
  //     },
  //     {
  //       id: `native`,
  //       title: `Native app (coming soon)`,
  //       description: `iOS and Android tablet and phone sizes`,
  //       disabled: true,
  //     },
  //   ],
  // },
  {
    page: `wizard_type`,
    id: `app_type`,
    name: `App type`,
    title: data => `What's the purpose of ${data.app_name ? `the ${data.app_name}` : 'your'} app?`,
    QuestionComponent: QuestionSingleChoice,
    yup_validation: yup.string().required(),
    default: () => [],
    choices: [
      {
        id: `social`,
        title: `Let people connect to each other`,
      },
      {
        id: `analytics`,
        title: `Track analytics`,
      },
      {
        id: `documents`,
        title: `Manage documents`,
      },
      {
        id: `tasks`,
        title: `Manage tasks`,
      },
      {
        id: `ecommerce`,
        title: `Sell things online`,
      },
      {
        id: `other`,
        title: `Something else`,
      },
    ],
  },
  {
    page: `wizard_data`,
    id: `data_collection_defaults`,
    name: `Data types`,
    title: () => `What kind of data will your app have?`,
    description: () => <>
      <p>
        All apps have some kind of data. Usually your users add data into an app, and almost all apps will show data back to users too.
      </p>
    </>,
    QuestionComponent: QuestionPills,
    yup_validation: yup.array().of(yup.string()).min(1),
    default: () => [], // todo - generate based on app type
    choices: [
      {
        id: `people`,
        title: `People`,
        icon: `account-multiple`,
        color: `orange`,
      },
      {
        id: `places`,
        title: `Places`,
        icon: `map-marker`,
        color: `red`,
      },
      {
        id: `documents`,
        title: `Documents`,
        icon: `file-document-outline`,
        color: `cyan`,
      },
      {
        id: `tasks`,
        title: `Tasks`,
        icon: `checkbox-marked-circle-outline`,
        color: `indigo`,
      },
      {
        id: `products`,
        title: `Products`,
        icon: `dollar`,
        color: `green`,
      },
      {
        id: `media`,
        title: `Video or audio`,
        icon: `play-box-multiple-outline`,
        color: `grape`,
      },
      {
        id: `things`,
        title: `Other stuff`,
        icon: `shape`,
        color: `gray`,
      },
    ],
  },
  {
    page: `wizard_users`,
    id: `welcome_users_intro`,
    name: `Welcome followup`,
    title: () => `Let's talk about your users`,
    description: () => <>
      {/* <p>
        UX stands for "User Experience"
      </p> */}
      <p>
        Before we can build a great user experience, we need to know a little about the people who will use your app.
      </p>
    </>,
    QuestionComponent: QuestionIntro,
    // youtubeId: `wbIXmB2ZLmA`,
  },
  // {
  //   page: `wizard_users`,
  //   id: `users_label`,
  //   name: `Users label`,
  //   title: () => `What do you call your users?`,
  //   example: `Example: A healthcare site might call their users "patients"; a gaming site might call their users "players"`,
  //   QuestionComponent: QuestionBigText,
  //   yup_validation: yup.string().required(),
  //   default: () => `Users`,
  // },
  {
    page: `wizard_users`,
    id: `users_context`,
    name: `User environment`,
    title: () => `Where will users mostly use your app?`,
    QuestionComponent: QuestionSelect,
    yup_validation: yup.string().required(),
    default: () => `users_personal`,
    choices: [
      {
        id: `users_personal`,
        title: `In their personal lives`,
      },
      {
        id: `users_work`,
        title: `At work`,
      },
    ],
  },
  {
    page: `wizard_users`,
    id: `users_tech_savvy`,
    name: `User tech savvy`,
    title: () => `How comfortable are your users with apps and technology?`,
    description: () => `This will help use decide how "hands-on" the app will be.`,
    example: `Example: users of a medication reminder app may be very low in tech-savvy; users of a computer programming tool would be high in tech savvy.`,
    QuestionComponent: QuestionSelect,
    yup_validation: yup.string().required(),
    default: () => `normal_savvy`,
    choices: [
      {
        id: `low_savvy`,
        title: `Not very comfortable`,
      },
      {
        id: `normal_savvy`,
        title: `Normal comfort`,
      },
      {
        id: `high_savvy`,
        title: `Extremely comfortable`,
      },
    ],
  },
  {
    page: `wizard_finish`,
    id: `wizard_finish_intro`,
    name: `Welcome followup`,
    title: () => `Hey, that's looking pretty good!`,
    description: () => <>
      <p>
        Looks like you could be a designer after all 😉
      </p>
      <p>
        Now we're REALLY going to get fancy. Reflow studio lets you toggle on and off pretty much every features you can think of, just with a single click.
      </p>
    </>,
    QuestionComponent: QuestionIntro,
    // youtubeId: `wbIXmB2ZLmA`,
  },
  // {
  //   page: `wizard_finish`,
  //   id: `your_email`,
  //   name: `Your email`,
  //   title: () => `What's your email?`,
  //   example: `Every few months, we'll email you updates about Reflow. Unsubscribe anytime.`,
  //   QuestionComponent: QuestionBigText,
  //   default: () => ``,
  // },
  {
    page: `accounts`,
    id: `accounts_intro`,
    name: `Accounts intro`,
    title: () => `Accounts`,
    description: data => <>
      <p>
        To save data in your app, {data.users_label || 'users'} will have to create accounts and be able to sign in.
      </p>
      <p>
        It's always a good idea to let people create accounts with their email and a password, but there are other ways to let people sign in.
      </p>
    </>,
    QuestionComponent: QuestionIntro,
    youtubeId: `wbIXmB2ZLmA`,
  },
  // {
  //   page: `accounts`,
  //   id: `auth_email_yn`,
  //   name: `Email authentication`,
  //   title: () => `Let users log in with email?`,
  //   QuestionComponent: QuestionToggle,
  //   default: () => 'auth_email_y',
  //   choices: [
  //     {
  //       id: `auth_email_n`,
  //     },
  //     {
  //       id: `auth_email_y`,
  //     },
  //   ],
  // },
  {
    page: `accounts`,
    id: `oauth_methods`,
    name: `Oauth providers`,
    title: data => `Besides email and password, how else will you let ${data.users_label || 'users'} log in?`,
    QuestionComponent: QuestionCheckboxes,
    yup_validation: yup.array().of(yup.string()),
    default: () => [],
    choices: [
      {
        id: `google`,
        title: `Google`,
      },
      {
        id: `facebook`,
        title: `Facebook`,
      },
      {
        id: `apple`,
        title: `Apple`,
      },
      {
        id: `twitter`,
        title: `Twitter`,
      },
      {
        id: `sms`,
        title: `Phone/SMS`,
      },
    ],
  },
  {
    page: `accounts`,
    id: `two_factor_yn`,
    name: `Two factor auth`,
    title: () => `Add two-factor authentication?`,
    description: () => `(For users who sign in with email.) This makes your app more secure by requiring a one-time code sent to their phone. Good if you store very sensitive data in your app.`,
    QuestionComponent: QuestionToggle,
    default: () => `two_factor_n`,
    choices: [
      {
        id: `two_factor_n`,
      },
      {
        id: `two_factor_y`,
      },
    ],
  },
  {
    page: `accounts`,
    id: `email_confirm_yn`,
    name: `Email confirmation`,
    title: () => `Ask users to confirm their email?`,
    description: () => `A good best-practice for extra security. It's also a good way to prevent your marketing emails from going to spam folders.`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `email_confirm_y`,
    // displayTest: data => data.auth_email_yn === `auth_email_yn`,
    choices: [
      {
        id: `email_confirm_n`,
      },
      {
        id: `email_confirm_y`,
      },
    ],
  },
  {
    page: `profile`,
    id: `profile_intro`,
    name: `Profiles intro`,
    title: () => `User profiles`,
    description: data => <>
      <p>
        Lots of apps have user profiles, or pages that let {data.users_label || 'users'} see information about each other.
      </p>
      <p>
        This is especially important for collaborative apps, or apps with a social component.
      </p>
    </>,
    QuestionComponent: QuestionIntro,
    youtubeId: `wbIXmB2ZLmA`,
  },
  {
    page: `profile`,
    id: `profiles_yn`,
    name: `Show profiles`,
    title: () => `Show public profiles for each user?`,
    description: () => `For collaborative apps, this helps users get a sense of who else is on the platform`,
    QuestionComponent: QuestionToggle,
    default: () => `profiles_n`,
    choices: [
      {
        id: `profiles_n`,
      },
      {
        id: `profiles_y`,
      },
    ],
  },
  {
    page: `profile`,
    id: `profile_attrs`,
    name: `Profile content`,
    title: () => `What information will appear on their profile?`,
    description: () => `We recommend you collect a few other pieces of information.`,
    QuestionComponent: QuestionCheckboxes,
    yup_validation: yup.array().of(yup.string()),
    default: () => [`profile_pic`, `bio`],
    displayTest: data => data.profiles_yn === `profiles_y`,
    choices: [
      {
        id: `profile_pic`,
        title: `Profile pic`,
        description: `Recommended`,
      },
      {
        id: `bio`,
        title: `Bio`,
        description: `Short description of themselves`,
      },
      {
        id: `social`,
        title: `Links to social media profiles`,
        description: `Helps other users find them online`,
      },
    ],
  },
  {
    page: `profile`,
    id: `follow_users_yn`,
    name: `Followers`,
    title: () => `Let people follow other users?`,
    description: data => `This will let ${data.users_label || 'users'} keep tabs on any public content that other ${data.users_label || 'users'} post`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `follow_users_n`,
    displayTest: data => data.profiles_yn === `profiles_y`,
    choices: [
      {
        id: `follow_users_n`,
      },
      {
        id: `follow_users_y`,
      },
    ],
  },
  {
    page: `profile`,
    id: `messaging_yn`,
    name: `Messaging`,
    title: () => `Let people send private messages to other users?`,
    description: data => `${data.users_label || 'users'} will have an inbox and get notifications of new messages`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `messaging_n`,
    displayTest: data => data.profiles_yn === `profiles_y`,
    choices: [
      {
        id: `messaging_n`,
      },
      {
        id: `messaging_y`,
      },
    ],
  },
  {
    page: `profile`,
    id: `online_status_yn`,
    name: `Online status`,
    title: () => `Show when users are online?`,
    description: data => `Useful for real-time apps where it's important to know if other ${data.users_label || 'users'} are online right now`,
    QuestionComponent: QuestionToggle,
    default: () => `online_status_n`,
    displayTest: data => data.profiles_yn === `profiles_y`,
    choices: [
      {
        id: `online_status_n`,
      },
      {
        id: `online_status_y`,
      },
    ],
  },
  {
    page: `profile`,
    id: `user_name`,
    name: `Name type`,
    title: () => `What kind of names should we give users?`,
    description: () => `We'll ask them when they first sign up`,
    QuestionComponent: QuestionSelect,
    yup_validation: yup.string().required(),
    default: () => `real_name`,
    displayTest: data => data.profiles_yn === `profiles_y`,
    choices: [
      {
        id: `real_name`,
        title: `Real names only`,
        description: `When your app requires peoples' real identities`,
        example: `Example: TurboTax, Airbnb`,
      },
      {
        id: `user_name`,
        title: `User names only`,
        description: `Good for content sites where people mostly want to contribute under an alias`,
        example: `Example: Reddit, Discord`,
      },
      {
        id: `real_name_and_user_name`,
        title: `Both`,
        description: `When most people will use real names, but some may contribute under an alias`,
        example: `Example: Twitter, StackOverflow`,
      },
    ],
  },
  {
    page: `teams`,
    id: `teams_intro`,
    name: `Teams intro`,
    title: () => `Team accounts`,
    description: data => <>
      <p>
        With teams, {data.users_label || 'users'} can invite each other to shared workspace for their company or workplace.
      </p>
      <p>
        If you charge for your app, all users on a team will be charged in a single invoice (rather than individually).
      </p>
      <p>
        Users will be able to join multiple teams if they choose.
      </p>
    </>,
    QuestionComponent: QuestionIntro,
    youtubeId: `wbIXmB2ZLmA`,
  },
  {
    page: `teams`,
    id: `teams_yn`,
    name: `Teams`,
    title: () => `Will users sign up as part of a team?`,
    description: () => `This could be a company, organization, or something else. Useful for apps used by companies.`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `teams_n`,
    choices: [
      {
        id: `teams_n`,
      },
      {
        id: `teams_y`,
      },
    ],
  },
  {
    page: `teams`,
    id: `team_invites_yn`,
    name: `Team invites`,
    title: () => `Allow users be invite other users to their team?`,
    description: data => `Recommended for most apps. The first user to sign up will be the team "Owner", and they can grant other teammates permissions to manage billing or invite other ${data.users_label || 'users'} (or transfer ownership of the team).
    Only people with proper permissions will be able to invite new members`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `team_invites_y`,
    displayTest: data => data.teams_yn === `teams_y`,
    choices: [
      {
        id: `team_invites_n`,
        consequence: `Users will not be able to invite other users to join their team. You'll have to set up teams manually in your software backend.`,
      },
      {
        id: `team_invites_y`,
      },
    ],
  },
  {
    page: `data_name`,
    id: `data_name_intro`,
    name: `Data name intro`,
    title: () => `Collection basics`,
    description: () => <>
      <p>
        A collection is like a tab in a spreadsheet. For example, you might have a collection called "People", "Events", "Tasks", "Places" etc.
      </p>
    </>,
    QuestionComponent: QuestionIntro,
    youtubeId: `wbIXmB2ZLmA`,
  },
  {
    page: `data_name`,
    id: `collection_name_plural`,
    name: `Collection name`,
    title: () => `What do you want to call this collection?`,
    example: `Example: Facebook has "friends", Instagram has "posts", Spotify has "songs" or "albums"`,
    QuestionComponent: QuestionBigText,
    yup_validation: yup.string().required(),
    default: () => `Dogs`,
  },
  {
    page: `data_name`,
    id: `collection_icon`,
    name: `Collection icon`,
    title: () => `What icon should we use to represent this collection?`,
    example: `Example: a collection of people could use the symbol "👤"`,
    QuestionComponent: QuestionBigText,
    yup_validation: yup.string().required().min(1),
    default: () => `dog`,
  },
  // {
  //   page: `data_name`,
  //   id: `collection_type`,
  //   name: `Collection type`,
  //   title: () => `What kind of thing is this collection?`,
  //   description: () => `We'll use your answer to pre-fill some data for you, but you can change anything later`,
  //   QuestionComponent: QuestionSelect,
  //   yup_validation: yup.string().required(), // todo limit to the options here, ie: () => this.choices.map(id => id)
  //   default: () => `collection_type_custom`,
  //   choices: [
  //     {
  //       id: `collection_type_custom`,
  //       title: `None of these`,
  //       description: `Start from scratch`,
  //     },
  //     {
  //       id: `collection_type_person`,
  //       title: `Person`,
  //       description: `Users, people, employees, friends, etc`,
  //     },
  //     {
  //       id: `collection_type_products`,
  //       title: `Product`,
  //       description: `Apparel, home goods, digital downloads, etc`,
  //     },
  //     {
  //       id: `collection_type_files`,
  //       title: `Files`,
  //       description: `Files, reports, data, etc`,
  //     },
  //     {
  //       id: `collection_type_media`,
  //       title: `Media`,
  //       description: `Song, audio, video, podcast, VR, etc`,
  //     },
  //   ],
  // },
  {
    page: `data_name`,
    id: `item_attributes`,
    name: `Attributes`,
    title: data => `What data do you want to collect about ${getCollectionName(data.collection_name_plural, { plural: true, capital: false})}?`,
    QuestionComponent: QuestionBuilder,
    EditorForm: AttributeForm,
    yup_validation: yup.array().of(yup.object()), // TODO - check validation on update (not a form element), more specific object validation, prevent duplicate ids
    default: () => [
      {
        id: `most_important`,
        title: `Most important`,
        items: [
          {
            id: `location`,
            title: `Location`,
          },
          {
            id: `timeline`,
            title: `Timeline`,
          },
        ],
      },
      {
        id: `less_important`,
        title: `Less important`,
        items: [
          {
            id: `created_by`,
            title: `Creator`,
          },
          {
            id: `created_on`,
            title: `Date created`,
          },
          {
            id: `updated_by`,
            title: `Most recently updated by`,
          },
          {
            id: `updated_on`,
            title: `Date updated`,
          },
        ],
      },
    ],
    suggestions: [
      {
        id: `name`,
        title: `Name`,
      },
      {
        id: `images`,
        title: `Gallery`,
        description: `Thumbnail, profile picture, or gallery of images`,
      },
      {
        id: `tags`,
        title: `Tags`,
      },
      {
        id: `status`,
        title: `Status`,
      },
      {
        id: `category`,
        title: `Category`,
      },
    ],
  },
  {
    page: `data_features`,
    id: `data_features_intro`,
    name: `Data features intro`,
    title: data => `${getCollectionName(data.collection_name_plural, { plural: false, capital: true})} features`,
    description: () => <>
      <p>
        Let's look at a few common features we can add to your collection
      </p>
    </>,
    QuestionComponent: QuestionIntro,
    youtubeId: `wbIXmB2ZLmA`,
  },
  {
    page: `data_features`,
    id: `profile_pic_yn`,
    name: `Profile picture`,
    title: data => `Should ${getCollectionName(data.collection_name_plural, { plural: true, capital: false})} have a picture?`,
    description: () => `This is a single picture that will appear when this item is in a list or being searched for`,
    example: `Example: your profile picture on facebook`,
    QuestionComponent: QuestionToggle,
    default: () => `profile_pic_n`,
    choices: [
      {
        id: `profile_pic_n`,
      },
      {
        id: `profile_pic_y`,
      },
    ],
  },
  {
    page: `data_features`,
    id: `gallery_banner_pic`,
    name: `Gallery banner`,
    title: data => `Should ${getCollectionName(data.collection_name_plural, { plural: true, capital: false})} have a gallery of images?`,
    description: () => `This is a large picture or set of pictures that show up large on an items page. If you want pictures but don't want them at the top of the page like this, you can also add pictures in the next step.`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `no_pic`,
    choices: [
      {
        id: `no_pic`,
      },
      {
        id: `gallery_pics`,
      },
    ],
  },
  {
    page: `data_features`,
    id: `share_yn`,
    name: `Sharing`,
    title: data => `Let ${data.users_label || 'users'} share a link to ${getCollectionName(data.collection_name_plural, { plural: true, capital: false})}?`,
    description: () => `They can copy a link to paste in a message or social media`,
    QuestionComponent: QuestionToggle,
    default: () => `share_n`,
    choices: [
      {
        id: `share_n`,
      },
      {
        id: `share_y`,
      },
    ],
  },
  {
    page: `data_features`,
    id: `favorite_yn`,
    name: `Favorites`,
    title: data => `Let ${data.users_label || 'users'} “favorite” ${getCollectionName(data.collection_name_plural, { plural: true, capital: false})}?`,
    description: () => `This will help them keep track of particular items they care about, and allow you to show popular items if you choose.`,
    QuestionComponent: QuestionToggle,
    default: () => `favorite_n`,
    choices: [
      {
        id: `favorite_n`,
      },
      {
        id: `favorite_y`,
      },
    ],
  },
  {
    page: `data_features`,
    id: `favorite_label`,
    name: `Favorites label`,
    title: () => `What should we call favorites?`,
    QuestionComponent: QuestionSelect,
    yup_validation: yup.string().required(),
    default: () => `favorites`,
    displayTest: data => data.favorite_yn === `favorite_y`,
    choices: [
      {
        id: `favorites`,
        title: `Favorites`,
      },
      {
        id: `likes`,
        title: `Likes`,
      },
      {
        id: `pins`,
        title: `Pins`,
      },
      {
        id: `library`,
        title: `Adds`,
      },
    ],
  },
  {
    page: `data_features`,
    id: `item_for_sale_yn`,
    name: `For sale`,
    title: data => `Let ${data.users_label || 'users'} buy ${getCollectionName(data.collection_name_plural, { plural: true, capital: false})} in your app?`,
    description: () => `Good for subscriptions, products`,
    QuestionComponent: QuestionToggle,
    default: () => `item_for_sale_n`,
    choices: [
      {
        id: `item_for_sale_n`,
      },
      {
        id: `item_for_sale_y`,
      },
    ],
  },
  {
    page: `data_features`,
    id: `item_ratings_reviews_yn`,
    name: `Ratings and reviews`,
    title: data => `Let ${data.users_label || 'users'} rate and leave reviews for ${getCollectionName(data.collection_name_plural, { plural: true, capital: false})}?`,
    description: () => `Good for products and locations`,
    QuestionComponent: QuestionToggle,
    default: () => `item_ratings_reviews_n`,
    choices: [
      {
        id: `item_ratings_reviews_n`,
      },
      {
        id: `item_ratings_reviews_y`,
      },
    ],
  },
  {
    page: `data_features`,
    id: `item_comments_yn`,
    name: `Comments`,
    title: data => `Let ${data.users_label || 'users'} leave comments on ${getCollectionName(data.collection_name_plural, { plural: true, capital: false})}?`,
    description: () => `Good for social media posts`,
    QuestionComponent: QuestionToggle,
    default: () => `item_comments_n`,
    choices: [
      {
        id: `item_comments_n`,
      },
      {
        id: `item_comments_y`,
      },
    ],
  },
  {
    page: `data_features`,
    id: `archive_yn`,
    name: `Archiving`,
    title: data => `Let users archive ${getCollectionName(data.collection_name_plural, { plural: true, capital: false})}?`,
    description: data => `This lets users move ${getCollectionName(data.collection_name_plural, { plural: true, capital: false})} out of the way without deleting them. Good for data that is often not relevant anymore, but needs to stick around just in case.`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `archive_n`,
    choices: [
      {
        id: `archive_n`,
      },
      {
        id: `archive_y`,
      },
    ],
  },
  {
    page: `actions`,
    id: `actions_intro`,
    name: `Actions intro`,
    title: data => `${getCollectionName(data.collection_name_plural, { plural: false, capital: true})} actions`,
    description: () => `Let's look at the "verbs" of your collection. What are the things you can actually *do* to your data?`,
    example: <>
      <p>
        Example: with Facebook friends, you can "comment", "message", and "unfriend"
      </p>
    </>,
    QuestionComponent: QuestionIntro,
    youtubeId: `wbIXmB2ZLmA`,
  },
  {
    page: `actions`,
    id: `item_actions`,
    name: `Actions`,
    title: () => `Actions`,
    QuestionComponent: QuestionCheckboxes,
    yup_validation: yup.array().of(yup.string()),
    default: () => ['message'],
    choices: [
      {
        id: `edit`,
        title: `Edit`,
        description: `Go to the edit screen`,
      },
      {
        id: `message`,
        title: `Send message`,
        description: `Send a message`,
      },
      {
        id: `favorite`,
        title: `Add to favorites`,
        description: `Required (from above)`,
      },
      {
        id: `share`,
        title: `Share`,
        description: `Open a share window with lots of share options`,
      },
      {
        id: `copy_link`,
        title: `Copy link`,
        description: `Copy a link to this thing`,
      },
      {
        id: `print`,
        title: `Print`,
        description: `If there's long text attr`,
      },
      {
        id: `open`,
        title: `Open`,
        description: `Open this item in the app`,
      },
      {
        id: `follow`,
        title: `Follow/unfollow`,
        description: `Follow this person/thing`,
      },
      {
        id: `rename`,
        title: `Rename`,
        description: `Change the name without editing it otherwise`,
      },
      {
        id: `duplicate`,
        title: `Duplicate`,
        description: `Immediately create a copy of this item`,
      },
      {
        id: `preview`,
        title: `Preview`,
        description: `Same as quick look?`,
      },
      {
        id: `download`,
        title: `Download`,
        description: `Download this item`,
      },
      {
        id: `export`,
        title: `Export`,
        description: `Export as a file`,
      },
      {
        id: `lock`,
        title: `Lock/Unlock`,
        description: `Mark this item as "locked" or "unlocked", which prevents users from editing it`,
      },
      {
        id: `report`,
        title: `Report inappropriate content`,
        description: `Let's users report inappropriate content on this item`,
      },
    ],
  },
  {
    page: `lists`,
    id: `lists_intro`,
    name: `Lists intro`,
    title: data => `Lists of ${getCollectionName(data.collection_name_plural, { plural: true, capital: false})}`,
    description: () => <>
      <p>
        In any app, generally you're going to see a list of items. This page will help you format that list in the best way possible.
      </p>
    </>,
    QuestionComponent: QuestionIntro,
    youtubeId: `wbIXmB2ZLmA`,
  },
  {
    page: `lists`,
    id: `list_size`,
    name: `List size`,
    title: data => `What's the most number of ${getCollectionName(data.collection_name_plural, { plural: true, capital: false})} that are likely to appear for each user?`,
    description: () => `This gives us an idea of how big to make each item in a list, and how many pages we might need to show.`,
    QuestionComponent: QuestionSingleChoice,
    yup_validation: yup.string().required(),
    default: () => `50`,
    choices: [
      {
        id: `5`,
        title: `About 5`,
      },
      {
        id: `50`,
        title: `About 50`,
      },
      {
        id: `500`,
        title: `About 500`,
      },
      {
        id: `more`,
        title: `5,000 or more`,
      },
    ],
  },
  {
    page: `lists`,
    id: `list_grouped_yn`,
    name: `List grouping`,
    title: data => `Organize ${getCollectionName(data.collection_name_plural, { plural: true, capital: false})} into groups?`,
    description: () => `This can help people find what they're looking for faster.`,
    QuestionComponent: QuestionToggle,
    displayTest: data => data.list_size !== `5` && data.list_size !== `50`,
    default: () => `flat_list`,
    choices: [
      {
        id: `list_grouped_n`,
      },
      {
        id: `list_grouped_y`,
      },
    ],
  },
  {
    page: `editing`,
    id: `editing_intro`,
    name: `Editing intro`,
    title: data => `Creating, editing, and deleting ${getCollectionName(data.collection_name_plural, { plural: true, capital: false})}`,
    description: () => <>
      <p>
        Most apps let users create new items, either with a big form or a step-by-step process that asks users questions.
      </p>
    </>,
    QuestionComponent: QuestionIntro,
    youtubeId: `wbIXmB2ZLmA`,
  },
  {
    page: `editing`,
    id: `editing_yn`,
    name: `Editing`,
    title: data => `Let users create, edit, and delete ${getCollectionName(data.collection_name_plural, { plural: true, capital: false})}?`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `editing_y`,
    choices: [
      {
        id: `editing_n`,
      },
      {
        id: `editing_y`,
      },
    ],
  },
  {
    page: `editing`,
    id: `quick_add_yn`,
    name: `Creating commonness`,
    title: data => `Show a "quick add" for ${getCollectionName(data.collection_name_plural, { plural: true, capital: false})}?`,
    description: data => `This will let users add a new ${getCollectionName(data.collection_name_plural, { plural: false, capital: false})} from any page on the app. This is a good idea if adding ${getCollectionName(data.collection_name_plural, { plural: true, capital: false})} is done very often?`,
    example: `Example: tasks, emails, tweets`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `quick_add_n`,
    displayTest: data => data.editing_yn === `editing_y`,
    choices: [
      {
        id: `quick_add_n`,
      },
      {
        id: `quick_add_y`,
      },
    ],
  },
  {
    page: `editing`,
    id: `templates_yn`,
    name: `Templates`,
    title: data => `Let people choose from a list of existing ${getCollectionName(data.collection_name_plural, { plural: false, capital: false})} templates?`,
    description: data => <>
      <p>
        Providing a list of {getCollectionName(data.collection_name_plural, { plural: false, capital: false})} templates can make it easier and faster to create {getCollectionName(data.collection_name_plural, { plural: true, capital: false})}.
      </p>
      <p>
        If most {getCollectionName(data.collection_name_plural, { plural: true, capital: false})} are pretty similar, templates will save people a lot of time. Otherwise, just have users start from scratch.
      </p>
    </>,
    QuestionComponent: QuestionToggle,
    displayTest: data => data.editing_yn === `editing_y`,
    default: () => `template_n`,
    choices: [
      {
        id: `template_n`,
      },
      {
        id: `template_y`,
      },
    ],
  },
  // {
  //   page: `editing`,
  //   id: `confirm_page_yn`,
  //   name: `Confirm page`,
  //   title: data => `Show a confirmation page before saving a new ${getCollectionName(data.collection_name_plural, { plural: false, capital: false})}?`,
  //   description: () => `This is good for things that are hard to undo once created (like events, purchases, and payments)`,
  //   QuestionComponent: QuestionToggle,
  //   yup_validation: yup.string().required(),
  //   default: () => `confirm_n`,
  //   displayTest: data => data.editing_yn === `editing_y`,
  //   choices: [
  //     {
  //       id: `confirm_n`,
  //     },
  //     {
  //       id: `confirm_y`,
  //     },
  //   ],
  // },
  {
    page: `editing`,
    id: `import_yn`,
    name: `Importing api`,
    title: data => `Let users import ${getCollectionName(data.collection_name_plural, { plural: true, capital: false})}?`,
    description: () => `This will allow users to upload or sync dogs from a file or integration.`,
    example: `Example: let users import their client list from Quickbooks or a CSV`,
    QuestionComponent: QuestionToggle,
    default: () => `import_n`,
    displayTest: data => data.editing_yn === `editing_y`,
    choices: [
      {
        id: `import_n`,
      },
      {
        id: `import_y`,
      },
    ],
  },
  {
    page: `editing`,
    id: `edit_wizard_yn`,
    name: `Edit flow`,
    title: data => `Should people create and edit ${getCollectionName(data.collection_name_plural, { plural: true, capital: false})} all at once or in steps?`,
    description: () => `Good for helping people so they don't feel intimidated. We recommend this because of how many attributes you have.`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `edit_wizard_n`,
    displayTest: data => data.editing_yn === `editing_y`,
    choices: [
      {
        id: `edit_wizard_n`,
      },
      {
        id: `edit_wizard_y`,
      },
    ],
  },
  {
    page: `editing`,
    id: `preview_item_yn`,
    name: `Preview item`,
    title: data => `Show a preview of the ${getCollectionName(data.collection_name_plural, { plural: false, capital: false})} they're editing?`,
    description: () => `This is useful for showing the visual consequences of a users's choices, especially for complex or custom-built items`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `preview_n`,
    displayTest: data => data.editing_yn === `editing_y`,
    choices: [
      {
        id: `preview_n`,
      },
      {
        id: `preview_y`,
      },
    ],
  },
  {
    page: `editing`,
    id: `edit_autosave_yn`,
    name: `Auto-save`,
    title: data => `Auto-save changes to ${getCollectionName(data.collection_name_plural, { plural: true, capital: false})} as users make edits?`,
    description: () => `Good for items with lots of long text; however, it makes it harder to edit an item as a "test" and change your mind.`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `edit_autosave_n`,
    displayTest: data => data.editing_yn === `editing_y`,
    choices: [
      {
        id: `edit_autosave_n`,
      },
      {
        id: `edit_autosave_y`,
      },
    ],
  },
  {
    page: `editing`,
    id: `trash_yn`,
    name: `Trash`,
    title: data => `Move deleted ${getCollectionName(data.collection_name_plural, { plural: false, capital: false})} to the "trash"?`,
    description: () => `This lets users easily change their mind if they delete something by accident`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `trash_n`,
    displayTest: data => data.editing_yn === `editing_y`,
    choices: [
      {
        id: `trash_n`,
      },
      {
        id: `trash_y`,
        consequence: `Users will be able to see deleted items in the trash. They will have 30 days to restore them before they are deleted permanently.`,
      },
    ],
  },
  {
    page: `editing`,
    id: `delete_confirm_yn`,
    name: `Confirm deletes`,
    title: data => `Double check before deleting ${getCollectionName(data.collection_name_plural, { plural: true, capital: false})}?`,
    description: () => `This is a good idea for most things, especially if they would be frustrating to lose if accidentally deleted`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `delete_confirm_y`,
    choices: [
      {
        id: `delete_confirm_n`,
      },
      {
        id: `delete_confirm_y`,
      },
    ],
  },
  {
    page: `onboarding`,
    id: `onboarding_intro`,
    name: `Onboarding intro`,
    title: () => `New user onboarding`,
    description: () => <>
      <p>
        The first-time experience for new users is one of the most important parts of your whole design.
      </p>
      <p>
        This is a really important opportunity to make a great first impression!
      </p>
    </>,
    QuestionComponent: QuestionIntro,
    youtubeId: `wbIXmB2ZLmA`,
  },
  {
    page: `onboarding`,
    id: `onboarding_purpose_yn`,
    name: `Onboarding purpose`,
    title: () => `Ask users what their primary goal is?`,
    description: () => `This can help you give them custom settings or onboarding to-dos later--plus it helps you understand what kind of new users you have`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `onboarding_purpose_n`,
    choices: [
      {
        id: `onboarding_purpose_n`,
      },
      {
        id: `onboarding_purpose_y`,
      },
    ],
  },
  {
    page: `onboarding`,
    id: `onboarding_role_yn`,
    name: `Onboarding role`,
    title: () => `Ask users what their job title or role is?`,
    description: () => `This can help you target them for sales later`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `onboarding_role_n`,
    displayTest: data => data.teams_yn === `teams_y`,
    choices: [
      {
        id: `onboarding_role_n`,
      },
      {
        id: `onboarding_role_y`,
      },
    ],
  },
  {
    page: `onboarding`,
    id: `onboarding_company_size_yn`,
    name: `Onboarding company size`,
    title: () => `Ask users how big their company is?`,
    description: () => `This is also useful for sales targeting`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `onboarding_company_size_n`,
    displayTest: data => data.teams_yn === `teams_y`,
    choices: [
      {
        id: `onboarding_company_size_n`,
      },
      {
        id: `onboarding_company_size_y`,
      },
    ],
  },
  {
    page: `onboarding`,
    id: `onboarding_welcome_yn`,
    name: `Onboarding welcome video`,
    title: () => `Show new users a welcome video?`,
    description: () => `This is a nice way to introduce them to the product, show them a familiar face, and make them feel at home`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `onboarding_welcome_n`,
    choices: [
      {
        id: `onboarding_welcome_n`,
      },
      {
        id: `onboarding_welcome_y`,
      },
    ],
  },
  {
    page: `onboarding`,
    id: `onboarding_checklist_yn`,
    name: `Onboarding checklist`,
    title: () => `Show users a checklist of things to help them get started?`,
    description: () => `This is a great way to guide people to engagement without getting in their way`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `onboarding_checklist_y`,
    choices: [
      {
        id: `onboarding_checklist_n`,
      },
      {
        id: `onboarding_checklist_y`,
      },
    ],
  },
  {
    page: `onboarding`,
    id: `user_attributes`,
    name: `User attributes`,
    title: () => `What else do you need to know about users?`,
    QuestionComponent: QuestionCheckboxes,
    yup_validation: yup.array().of(yup.string()),
    default: () => [],
    choices: [
      {
        id: `phone`,
        title: `Phone number`,
        description: `Useful if you plan to do direct sales with enterprise customers, but sometimes seen as creepy for consumer apps`,
      },
      {
        id: `birthday`,
        title: `Birthday`,
        description: `Important for apps that limit users by age`,
      },
      {
        id: `mailing_address`,
        title: `Mailing address`,
        description: `Useful if you need to send packages`,
      },
    ],
  },
  {
    page: `dashboard`,
    id: `dashboard_intro`,
    name: `Dashboard intro`,
    title: () => `Dashboard`,
    description: () => <>
      <p>
        A dashboard is a page that summarizes all the data in the app. It's especially useful for complex apps where users want to get a "pulse" of what's happening lately.
      </p>
    </>,
    QuestionComponent: QuestionIntro,
    youtubeId: `wbIXmB2ZLmA`,
  },
  {
    page: `dashboard`,
    id: `dashboard_yn`,
    name: `Dashboard`,
    title: () => `Show a custom dashboard?`,
    description: () => `This is what users will see when they first log in (or click your logo). Useful if there's a lot of information you want to summarize when users log in.`,
    QuestionComponent: QuestionToggle,
    default: () => `dashboard_y`, // necessary for dashboard preview in wizard
    choices: [
      {
        id: `dashboard_n`,
      },
      {
        id: `dashboard_y`,
      },
    ],
  },
  // {
  //   page: `dashboard`,
  //   id: `dashboard_items`,
  //   name: `Dashboard items`,
  //   title: () => `What do you want to show on the dashboard?`,
  //   description: () => `Think about what would be most useful if they were sitting down at a desk to start their day.`,
  //   QuestionComponent: QuestionBuilder,
  //   EditorForm: WidgetForm,
  //   yup_validation: yup.array().of(yup.object()),
  //   displayTest: data => data.dashboard_yn === `dashboard_y`,
  //   default: () => [
  //     {
  //       id: `dashboard_main`,
  //       title: `Most important dashboard`,
  //       items: [
  //         {
  //           id: `stats`,
  //           title: `Statistics around what's happened lately`,
  //           example: `Example: number of deals today`,
  //         },
  //         {
  //           id: `chart`,
  //           title: `Chart`,
  //           example: `Example: growth over time`,
  //         },
  //       ],
  //     },
  //     {
  //       id: `dashboard_secondary`,
  //       title: `Less important`,
  //       items: [
  //         {
  //           id: `timeline`,
  //           title: `A timeline of recent events`,
  //           example: `Example: all new and updated items`,
  //         },
  //       ],
  //     },
  //   ],
  //   suggestions: [
  //     {
  //       id: `top`,
  //       title: `Top or popular items`,
  //       example: `Example: most popular store items today`,
  //     },
  //   ],
  // },
  {
    page: `search`,
    id: `search_intro`,
    name: `Search intro`,
    title: () => `Global search`,
    description: () => <>
      <p>
      Adding a global search feature makes it so any user can find things quickly in your app. This is especially important for power users etc.
      </p>
    </>,
    QuestionComponent: QuestionIntro,
    youtubeId: `wbIXmB2ZLmA`,
  },
  {
    page: `search`,
    id: `search_yn`,
    name: `Search`,
    title: () => `Include a search feature?`,
    description: () => `Highly recommended because you have lots of kinds of items with many items in each (500+)`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `search_y`,
    choices: [
      {
        id: `search_n`,
      },
      {
        id: `search_y`,
      },
    ],
  },
  {
    page: `pricing`,
    id: `pricing_intro`,
    name: `Pricing intro`,
    title: () => `Pricing`,
    description: () => <>
      <p>
        Lots of apps charge users for use. Pricing isn't easy though...
      </p>
    </>,
    QuestionComponent: QuestionIntro,
    youtubeId: `wbIXmB2ZLmA`,
  },
  {
    page: `pricing`,
    id: `charge_yn`,
    name: `Charging`,
    title: () => `Will you charge for your app?`,
    description: () => `If you will require at least some users to pay`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `charge_n`,
    choices: [
      {
        id: `charge_n`,
      },
      {
        id: `charge_y`,
      },
    ],
  },
  {
    page: `pricing`,
    id: `free_tier_yn`,
    name: `Free tier`,
    title: () => `Will you offer a free tier?`,
    QuestionComponent: QuestionToggle,
    description: () => `This will let low-usage users stay on your app forever`,
    yup_validation: yup.string().required(),
    default: () => `free_tier_n`,
    displayTest: data => data.charge_yn !== `charge_n`,
    choices: [
      {
        id: `free_tier_n`,
      },
      {
        id: `free_tier_y`,
      },
    ],
  },
  {
    page: `pricing`,
    id: `pricing_free_trial_yn`,
    name: `Free trial`,
    title: () => `Will you offer a free trial to users?`,
    description: () => `Free trials are good if you don't have a big sales team.`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `pricing_free_trial_n`,
    displayTest: data => data.charge_yn !== `charge_n`,
    choices: [
      {
        id: `pricing_free_trial_n`,
      },
      {
        id: `pricing_free_trial_y`,
      },
    ],
  },
  {
    page: `notifications`,
    id: `notifications_intro`,
    name: `Notifications intro`,
    title: () => `Notifications`,
    description: () => <>
      <p>
        Notifications are a key lever for creating engagement.
      </p>
    </>,
    QuestionComponent: QuestionIntro,
    youtubeId: `wbIXmB2ZLmA`,
  },
  {
    page: `notifications`,
    id: `notifications_platform`,
    name: `Notifications platform`,
    title: () => `Where will users see notifications?`,
    description: () => `They will be alerted when something changes in the app`,
    QuestionComponent: QuestionCheckboxes,
    yup_validation: yup.array().of(yup.string()),
    default: () => [`in_app`, `email`],
    choices: [
      {
        id: `in_app`,
        title: `In-app`,
        description: `Users will see notifications in the app itself`,
      },
      {
        id: `email`,
        title: `Email`,
        description: `Users will get notifications sent to their email address`,
      },
      {
        id: `native_mobile`,
        title: `Native mobile (coming soon)`,
        description: `Users will get notifications on their iOS or Android devices if have the native mobile app installed`,
        disabled: true,
      },
    ],
  },
  {
    page: `email`,
    id: `email_intro`,
    name: `Email intro`,
    title: () => `Lifecycle emails`,
    description: () => <>
      <p>
        One of the best marketing tools you have at your disposal is your email list.
      </p>
      <p>
        Getting the right set of drip emails for your customers early is critical for building a passionate user base.
      </p>
    </>,
    QuestionComponent: QuestionIntro,
    youtubeId: `wbIXmB2ZLmA`,
  },
  {
    page: `email`,
    id: `email_types`,
    name: `Emails`,
    title: () => `What kind of emails will you send to users?`,
    description: () => `Lifecycle or "drip" emails from your app are one of the best ways to keep customers engaged.`,
    QuestionComponent: QuestionCheckboxes,
    yup_validation: yup.array().of(yup.string()),
    default: () => [`welcome_email`],
    choices: [
      {
        id: `welcome_email`,
        title: `Welcome email`,
        description: `A note from the founder and links to support docs`,
      },
      {
        id: `how_to`,
        title: `How-to email`,
        description: `Guide users to do a specific task`,
      },
      {
        id: `demo_invite`,
        title: `Demo invite`,
        description: `Host a live demo or sales workshop`,
      },
      {
        id: `case_studies`,
        title: `Case studies`,
        description: `Social proof that others like your product`,
      },
      {
        id: `trial_expiring`,
        title: `Trial expiring`,
        description: `Warn users that their free trial is about to expire`,
      },
      {
        id: `customer_welcome`,
        title: `Customer welcome`,
        description: `Thank you for new paying customers`,
      },
      {
        id: `usage_review`,
        title: `Usage review`,
        description: `Periodic update with usage stats`,
      },
      {
        id: `survey`,
        title: `Survey`,
        description: `Ask users to rate their experience`,
      },
    ],
  },
  {
    page: `localization`,
    id: `localization_intro`,
    name: `Localization intro`,
    title: () => `Localization`,
    description: () => <>
      <p>
        One of the beautiful things about the internet is that you can offer your software to anyone in the world.
      </p>
      <p>
        Going international is easier said than done, though. There are a few critical considerations to make when selling outside of the USA.
      </p>
    </>,
    QuestionComponent: QuestionIntro,
    youtubeId: `wbIXmB2ZLmA`,
  },
  {
    page: `localization`,
    id: `language_yn`,
    name: `Language`,
    title: () => `Allow users to set their language?`,
    description: () => `Useful if your app serves a diverse user base`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `language_n`,
    choices: [
      {
        id: `language_n`,
      },
      {
        id: `language_y`,
      },
    ],
  },
  {
    page: `localization`,
    id: `time_zone_yn`,
    name: `Time zone`,
    title: () => `Allow users to set their time zone?`,
    description: () => `Useful if your app will schedule events etc`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `time_zone_n`,
    choices: [
      {
        id: `time_zone_n`,
      },
      {
        id: `time_zone_y`,
      },
    ],
  },
  {
    page: `localization`,
    id: `currency_yn`,
    name: `Currency`,
    title: () => `Let users choose their default currency?`,
    description: () => `Useful if your app manages payments of finances in multiple locales`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `currency_n`,
    choices: [
      {
        id: `currency_n`,
      },
      {
        id: `currency_y`,
      },
    ],
  },
  {
    page: `localization`,
    id: `week_start_yn`,
    name: `Week start`,
    title: () => `Let users change the first day of the week?`,
    description: () => `Many non-european countries' calendars show the week starting on Sunday. In European calendars, weeks usually start on Monday.`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `week_start_n`,
    choices: [
      {
        id: `week_start_n`,
      },
      {
        id: `week_start_y`,
      },
    ],
  },
  {
    page: `legal`,
    id: `legal_intro`,
    name: `Legal intro`,
    title: () => `Legal stuff`,
    description: () => <>
      <p>
        Legal stuff isn't fun, but it's necessary to protect your business (and to protect your customers' data).
      </p>
      <p style={{fontStyle: 'italic'}}>
        NOTE: We are not lawyers and this is not legal advice!
      </p>
    </>,
    QuestionComponent: QuestionIntro,
    youtubeId: `wbIXmB2ZLmA`,
  },
  {
    page: `legal`,
    id: `legal_name`,
    name: `Legal name`,
    title: () => `What's your company's legal name?`,
    description: () => `This will be used in copyright text and legal pages.`,
    example: `Example: "Global Enterprises LLC"`,
    QuestionComponent: QuestionBigText,
    yup_validation: yup.string().required(),
    default: data => `${data.app_name || `Acme`}, Inc`,
  },
  {
    page: `legal`,
    id: `legal_links`,
    name: `Legal links`,
    title: () => `What kinds of legal links do you need to include?`,
    description: () => `These are common to most apps`,
    QuestionComponent: QuestionCheckboxes,
    yup_validation: yup.array().of(yup.string()),
    default: () => [`terms`, `privacy`],
    choices: [
      {
        id: `terms`,
        title: `Terms of service`,
        description: `Highly recommended for all apps`,
      },
      {
        id: `privacy`,
        title: `Privacy policy`,
        description: `Highly recommended for all apps`,
      },
      {
        id: `dmca`,
        title: `DMCA`,
        description: `For apps that show publicly available content/media`,
      },
      {
        id: `gdpr`,
        title: `GDPR`,
        description: `For companies who operate in Europe`,
      },
      {
        id: `accessibility`,
        title: `Accessibility statement`,
        description: `For companies with certain compliance requirements`,
      },
    ],
  },
  {
    page: `main_pages`,
    id: `main_pages_intro`,
    name: `Main pages intro`,
    title: () => `Main navigation`,
    description: () => <>
      <p>
        Navigation means helping your users get around your app. We'll take care of the heavy lifting for you, we just need to know which pages to include and which order you want them in.
      </p>
      <p>
        Let's help users get around your app. What other pages would you like to show?
      </p>
      <p>
        These pages don't necessarily show any new information, but they can help your users find information faster or more easily.
      </p>
    </>,
    QuestionComponent: QuestionIntro,
    youtubeId: `wbIXmB2ZLmA`,
  },
  {
    page: `main_pages`,
    id: `main_pages_items`,
    name: `Main pages`,
    title: () => `Main pages`,
    QuestionComponent: QuestionCheckboxes,
    yup_validation: yup.array().of(yup.string()),
    default: () => [],
    choices: [
      {
        id: `favorites`,
        title: `Favorites`,
        description: `Show a list of all items the user has favorited, liked, or pinned`,
      },
      {
        id: `following`,
        title: `Following`,
        description: `Show a list of all items from people this user has followed`,
      },
      {
        id: `suggested`,
        title: `Suggested`,
        description: `Show a list of all items sorted by an algorithm's score of relevance for this user`,
      },
      {
        id: `popular`,
        title: `Popular`,
        description: `Show a list of the most popular items across all users in the system`,
      },
      {
        id: `recent_changes`,
        title: `Recent changes`,
        description: `Show a stream of items ordered by which ones have changed most recently`,
      },
      {
        id: `reports`,
        title: `Reports`,
        description: `Show a list of generated documents or analytics`,
      },
      {
        id: `calendar`,
        title: `Calendar`,
        description: `Show a calendar view of all items with dates`,
      },
      {
        id: `map`,
        title: `Map`,
        description: `Show a big map. Anything with a location will appear on the map.`,
      },
      {
        id: `kanban`,
        title: `Status board`,
        description: `Show a kanban-style status board of all items with a status`,
      },
    ],
  },
  {
    page: `other_pages`,
    id: `other_pages_intro`,
    name: `Other pages intro`,
    title: () => `Secondary navigation`,
    description: () => <>
      <p>
        These smaller pages help users get help, etc
      </p>
    </>,
    QuestionComponent: QuestionIntro,
    youtubeId: `wbIXmB2ZLmA`,
  },
  {
    page: `other_pages`,
    id: `nav_other_pages`,
    name: `Other pages`,
    title: () => `Other pages`,
    QuestionComponent: QuestionCheckboxes,
    yup_validation: yup.array().of(yup.string()),
    default: () => [],
    choices: [
      {
        id: `settings`,
        title: `Settings`,
        description: `Take users to the list of settings`,
      },
      {
        id: `help`,
        title: `Help/Support`,
        description: `Direct users to a support page or knowledge base`,
      },
      {
        id: `upgrade`,
        title: `Upgrade plan`,
        description: `Send users to the up-sell page`,
      },
      {
        id: `whats_new`,
        title: `New features`,
        description: `Show users a list of recent changes to the app`,
      },
      {
        id: `inbox`,
        title: `Message inbox`,
        description: `Show users their inbox of private messages`,
      },
      {
        id: `feedback`,
        title: `Feedback`,
        description: `Show a feedback form where users can send you comments`,
      },
      {
        id: `notifications`,
        title: `Notifications`,
        description: `Show in-app notifications`,
      },
      {
        id: `invite`,
        title: `Invite teammates`,
        description: `Invite teammates to the app`,
      },
      {
        id: `logout`,
        title: `Log out`,
        description: `Let the user log out quickly`,
      },
      {
        id: `cart`,
        title: `Cart`,
        description: `Show items ready for purchase`,
      },
      {
        id: `checklist`,
        title: `Onboarding checklist`,
        description: `Common tasks for new users`,
      },
    ],
  },
  {
    page: `navigation_type`,
    id: `navigation_type_intro`,
    name: `Navigation type intro`,
    title: () => `Navigation type`,
    description: () => <>
      <p>
        Depending on how many pages, you can do navigation very differently
      </p>
    </>,
    QuestionComponent: QuestionIntro,
    youtubeId: `wbIXmB2ZLmA`,
  },
  {
    page: `navigation_type`,
    id: `navigation_type_picker`,
    name: `Navigation type`,
    title: () => `What style of navigation works best for your app?`,
    QuestionComponent: QuestionSingleChoice,
    yup_validation: yup.string().required(),
    default: () => `vertical`,
    choices: [
      {
        id: `vertical`,
        title: `Vertical/side navigation`,
        description: `Lots of room for pages, feels like a "web app"`,
      },
      {
        id: `horizontal`,
        title: `Horizontal/top navigation`,
        description: `Simple and clean, feels like a "website"`,
      },
    ],
  },
  {
    page: `colors`,
    id: `colors_intro`,
    name: `Colors intro`,
    title: () => `Colors`,
    description: () => <>
      <p>
        Now that we've got our pages and features down, it's time to make this app look good 💅
      </p>
      <p>
        This is more than just pretty colors. How your app looks affects your brand, and can even make it easier to use.
      </p>
      <p>
        One of the biggest ways you can express your brand in your app is through color.
      </p>
    </>,
    QuestionComponent: QuestionIntro,
    youtubeId: `wbIXmB2ZLmA`,
  },
  {
    page: `colors`,
    id: `color_primary`,
    name: `Brand color`,
    title: () => `What is your main brand color?`,
    description: () => `This will help us add a little bit of branded customization to the app`,
    QuestionComponent: QuestionSelect,
    yup_validation: yup.string().required(),
    default: () => `blue`,
    choices: [
      {
        id: `gray`,
        title: `Gray`,
        description: `Minimal, sophisticated`,
        example: `Example: Apple, Uber`,
      },
      {
        id: `red`,
        title: `Red`,
        description: `Bold, passionate`,
        example: `Example: Netflix, Youtube, Doordash`,
      },
      {
        id: `pink`,
        title: `Pink`,
        description: `Odd, iconoclastic`,
        example: `Example: Lyft, T-mobile`,
      },
      {
        id: `purple`,
        title: `Purple`,
        description: `Extravagant, royal`,
        example: `Example: Twitch, Roku`,
      },
      {
        id: `indigo`,
        title: `Indigo`,
        description: `Corporate, stable`,
        example: `Example: LinkedIn`,
      },
      {
        id: `blue`,
        title: `Blue`,
        description: `Classic, inoffensive`,
        example: `Example: Facebook, Venmo`,
      },
      {
        id: `aqua`,
        title: `Aqua`,
        description: `Serene, relaxing`,
        example: `Example: Tiffany, Canva`,
      },
      {
        id: `green`,
        title: `Green`,
        description: `Profitable, natural`,
        example: `Example: Whole Foods, John Deere`,
      },
      {
        id: `lime`,
        title: `Lime`,
        description: `Funky, flavorful`,
        example: `Example: Spotify, HelloFresh`,
      },
      {
        id: `yellow`,
        title: `Yellow`,
        description: `Friendly, simple`,
        example: `Example: Snap, Bumble`,
      },
      {
        id: `orange`,
        title: `Orange`,
        description: `Fresh, bright`,
        example: `Example: Soundcloud, Firefox`,
      },
    ],
  },
  {
    page: `colors`,
    id: `color_boldness`,
    name: `Color boldness`,
    title: () => `Use bold colors?`,
    description: () => `This is another way to give your brand personality and make it stand out. Bold colors can be a little intense though.`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `color_clean`,
    choices: [
      {
        id: `color_clean`,
      },
      {
        id: `color_bold`,
      },
    ],
  },
  {
    page: `colors`,
    id: `color_gray_bg`,
    name: `Gray background`,
    title: () => `Use a gray background?`,
    description: () => `This can make things feel a little more organized, but maybe a little less simple and clean.`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `color_gray_n`,
    choices: [
      {
        id: `color_gray_n`,
      },
      {
        id: `color_gray_y`,
      },
    ],
  },
  {
    page: `colors`,
    id: `color_dark_mode`,
    name: `Dark mode`,
    title: () => `Add a dark mode option?`,
    description: () => `Good for most users, especially pro users or users who use the app at night`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `color_dark_mode_n`,
    choices: [
      {
        id: `color_dark_mode_n`,
      },
      {
        id: `color_dark_mode_y`,
      },
    ],
  },
  {
    page: `feel`,
    id: `feel_intro`,
    name: `Feel intro`,
    title: () => `Feel`,
    description: () => <>
      <p>
        These are a few more things that can help you define your brand.
      </p>
    </>,
    QuestionComponent: QuestionIntro,
    youtubeId: `wbIXmB2ZLmA`,
  },
  {
    page: `feel`,
    id: `font_display`,
    name: `Fonts`,
    title: () => `What kind of font should we use for headings?`,
    description: () => <>
      <p>
      Choosing a custom heading font can give your app a little extra style and personality
      </p>
      <p>
        All these fonts are available for both web and mobile via Google Fonts (free) or Adobe Typekit ($5/month)
      </p>
    </>,
    QuestionComponent: QuestionSelect,
    yup_validation: yup.string().required(),
    default: () => `simple`,
    choices: [
      {
        id: `simple`,
        title: `Simple`,
        description: `The normal font`,
      },
      {
        id: `owners`,
        title: `Wide and bold`,
        description: `Youthful, aggressive, funky`,
        example: `Font: Owners wide black`,
      },
      {
        id: `garamond`,
        title: `Traditional`,
        description: `Solid, old, reliable, academic`,
        example: `Font: Garamond`,
      },
      {
        id: `clarendon`,
        title: `Slab`,
        description: `Offbeat, historic, charming`,
        example: `Font: clarendon`,
      },
    ],
  },
  {
    page: `feel`,
    id: `ui_roundedness`,
    name: `Roundedness`,
    title: () => `Rounded corners`,
    description: () => `Most apps have subtly rounded corners. More rounded corners make the app feel friendlier; less rounding feels more "rigid"`,
    QuestionComponent: QuestionSelect,
    yup_validation: yup.string().required(),
    default: () => `normal_rounded`,
    choices: [
      {
        id: `not_rounded`,
        title: `Not rounded at all`,
        description: `Technical, strong, rigid`,
      },
      {
        id: `normal_rounded`,
        title: `Normal roundedness`,
        description: `Clean, modern, professional`,
      },
      {
        id: `very_rounded`,
        title: `Very rounded`,
        description: `Youthful, goofy, funny, playful`,
      },
    ],
  },
]

export default Questions
